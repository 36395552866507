export const QUIZ_QUESTIONS = [
    {
        id: 'snore',
        question: 'Do you Snore Loudly (loud enough to be heard through closed doors)?',
        options: ['yes', 'no']
    },
    {
        id: 'tired',
        question: 'Do you often feel tired, fatigued, or sleepy during the day?',
        options: ['yes', 'no']
    },
    {
        id: 'observed',
        question: 'Has anyone Observed you Stop Breathing or Choking/Gasping during your sleep?',
        options: ['yes', 'no']
    },
    {
        id: 'bp',
        question: 'Do you have or have you been treated for high blood pressure?',
        options: ['yes', 'no']
    },
    {
        id: 'gender',
        question: 'Please share your gender',
        options: ['male', 'female']
    },
    {
        id: 'collar',
        question: 'Is your shirt collar 16 inches/40 cm or larger?',
        options: ['yes', 'no']
    },
    {
        id: 'age',
        question: 'Please enter your age',
        
    },
    {
        id:'height',
        question: 'Please enter your height in cms',
    },
    {
        id: 'weight',
        question: 'Please enter your weight in kgs',
    },
    {
        id: 'bmi',
        question: 'Calculated BMI',
    },
   
   
]; 