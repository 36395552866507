import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    const isUserLoggedIn = localStorage.getItem('isUserLoggedIn') === 'true';
    const isDoctorLoggedIn = localStorage.getItem('isDoctorLoggedIn') === 'true';
    const isCompanyLoggedIn = localStorage.getItem('isCompanyLoggedIn') === 'true';
    const isAdminLoggedIn = localStorage.getItem('isAdminLoggedIn') === 'true';

    const toggleMenu = () => setIsOpen(!isOpen);
    const closeMenu = () => setIsOpen(false);

    const isUserRoute = location.pathname.startsWith('/user');
    const isDoctorRoute = location.pathname.startsWith('/doctor');
    const isCompanyRoute = location.pathname.startsWith('/company');
    const isAdminRoute = location.pathname.startsWith('/admin');
    const isAuthRoute = location.pathname.startsWith('/auth');

    useEffect(() => {
        const handleScroll = () => setIsScrolled(window.scrollY > 0);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <nav className={`w-full fixed top-0 py-4 z-50 transition-shadow duration-300 ${isScrolled ? 'shadow-md bg-white' : 'bg-opacity-90'}`}>
            <div className="container max-w-screen-xl mx-auto px-4 flex justify-between items-center">
                <Link to="/" className="flex items-center">
                    <img src="/logo.png" alt="YourNidra Logo" className="h-10" />
                </Link>

                {/* Desktop Navigation (excluding Doctor links) */}
                <div className="hidden lg:flex space-x-8 items-center">
                    {!isUserRoute && !isDoctorRoute && !isCompanyRoute && !isAdminRoute && !isAuthRoute && (
                        <>
                            <a href="/#symptoms" className="text-gray-700 hover:text-darkGreen">About OSA</a>
                            <a href="/#testimonials" className="text-gray-700 hover:text-darkGreen">Testimonials</a>
                            <a href="/#footer" className="text-gray-700 hover:text-darkGreen">Contact</a>
                            <Link to="/blogs" className="text-gray-700 hover:text-darkGreen">Blogs</Link>
                            {isUserLoggedIn ? (
                                <Link to="/user/dashboard" className="bg-darkGreen text-white px-4 py-2 rounded-lg shadow">My Account</Link>
                            ) : (
                                <Link to="/auth/user/login" className="bg-darkGreen text-white px-4 py-2 rounded-lg shadow">Login</Link>
                            )}
                        </>
                    )}

                    {isUserLoggedIn && isUserRoute && (
                        <>
                            <Link to="/user/dashboard" className="text-gray-700 hover:text-darkGreen">Dashboard</Link>
                            <Link to="/user/profile" className="text-gray-700 hover:text-darkGreen">Profile</Link>
                            <Link to="/user/questionnaire" className="text-gray-700 hover:text-darkGreen">Self Assessment</Link>
                            <Link to="/user/sleep-study" className="text-gray-700 hover:text-darkGreen">Sleep Study</Link>
                            <Link to="/user/consult" className="text-gray-700 hover:text-darkGreen">Consultation</Link>
                            <Link to="/user/device" className="text-gray-700 hover:text-darkGreen">Devices</Link>
                            <Link to="/user/referral" className="text-gray-700 hover:text-darkGreen">Referrals</Link>
                            <Link to="/user/faq" className="text-gray-700 hover:text-darkGreen">FAQs</Link>
                            <Link to="/user/logout" className="text-red-600 hover:text-red-700">Logout</Link>
                        </>
                    )}

                    {isCompanyLoggedIn && isCompanyRoute && (
                        <>
                            <Link to="/company/dashboard" className="text-gray-700 hover:text-darkGreen">Dashboard</Link>
                            <Link to="/company/reports" className="text-gray-700 hover:text-darkGreen">Reports</Link>
                            <Link to="/company/settings" className="text-gray-700 hover:text-darkGreen">Settings</Link>
                            <Link to="/company/logout" className="text-red-600 hover:text-red-700">Logout</Link>
                        </>
                    )}

                    {isAdminLoggedIn && isAdminRoute && (
                        <>
                            <Link to="/admin/dashboard" className="text-gray-700 hover:text-darkGreen">Dashboard</Link>
                            <Link to="/admin/users" className="text-gray-700 hover:text-darkGreen">Manage Users</Link>
                            <Link to="/admin/create-users" className="text-gray-700 hover:text-darkGreen">Create Multiple Users</Link>
                            <Link to="/admin/settings" className="text-gray-700 hover:text-darkGreen">Settings</Link>
                            <Link to="/admin/logout" className="text-red-600 hover:text-red-700">Logout</Link>
                        </>
                    )}
                </div>


                {/* Hamburger Menu (Mobile Only) */}
                <div className="lg:hidden"> 
                    <button onClick={toggleMenu} className="text-gray-800">
                        {isOpen ? <FaTimes className="w-6 h-6" /> : <FaBars className="w-6 h-6" />}
                    </button>
                </div>
            </div>


            {/* Mobile Navigation (including Doctor links) */}
            {isOpen && (
                <div className="lg:hidden flex flex-col space-y-4 mt-4 text-center bg-white p-4 shadow-md">
                    {!isUserRoute && !isDoctorRoute && !isCompanyRoute && !isAdminRoute && !isAuthRoute && (
                        <>
                            <a href="/#symptoms" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>About OSA</a>
                            <a href="/#testimonials" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Testimonials</a>
                            <a href="/#footer" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Contact</a>
                            <Link to="/blogs" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Blogs</Link>
                            {isUserLoggedIn ? (
                                <Link to="/user/dashboard" className="bg-darkGreen text-white px-4 py-2 rounded-lg shadow" onClick={closeMenu}>My Account</Link>
                            ) : (
                                <Link to="/auth/user/login" className="bg-darkGreen text-white px-4 py-2 rounded-lg shadow" onClick={closeMenu}>Login</Link>
                            )}
                        </>
                    )}


                    {isUserLoggedIn && isUserRoute && (
                        <>
                            <Link to="/user/dashboard" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Dashboard</Link>
                            <Link to="/user/profile" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Profile</Link>
                            <Link to="/user/questionnaire" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Self Assessment</Link>
                            <Link to="/user/sleep-study" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Sleep Study</Link>
                            <Link to="/user/consult" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Consultation</Link>
                            <Link to="/user/device" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Devices</Link>
                            <Link to="/user/referral" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Referrals</Link>
                            <Link to="/user/faq" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>FAQs</Link>
                            <Link to="/user/logout" className="text-red-600 hover:text-red-700" onClick={closeMenu}>Logout</Link>
                        </>
                    )}



                    {isDoctorLoggedIn && isDoctorRoute && (
                        <>
                            <Link to="/doctor/dashboard" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Dashboard</Link>
                            <Link to="/doctor/schedule" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Schedule</Link>
                            <Link to="/doctor/patients" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Patients</Link>
                            <Link to="/auth/doctor/logout" className="text-red-600 hover:text-red-700" onClick={closeMenu}>Logout</Link>
                        </>
                    )}

                    {isCompanyLoggedIn && isCompanyRoute && (
                        <>
                            <Link to="/company/dashboard" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Dashboard</Link>
                            <Link to="/company/reports" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Reports</Link>
                            <Link to="/company/settings" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Settings</Link>
                            <Link to="/company/logout" className="text-red-600 hover:text-red-700" onClick={closeMenu}>Logout</Link>
                        </>
                    )}

                    {isAdminLoggedIn && isAdminRoute && (
                        <>
                            <Link to="/admin/dashboard" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Dashboard</Link>
                            <Link to="/admin/users" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Manage Users</Link>
                            <Link to="/admin/create-users" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Create Multiple Users</Link>
                            <Link to="/admin/settings" className="text-gray-700 hover:text-darkGreen" onClick={closeMenu}>Settings</Link>
                            <Link to="/admin/logout" className="text-red-600 hover:text-red-700" onClick={closeMenu}>Logout</Link>
                        </>
                    )}
                </div>
            )}
        </nav>
    );
};

export default Navbar;