// features/doctor/components/DoctorUserModal.tsx
import React, { useState } from 'react';
import { checkExistingPatient, createUser, assignPatientToDoctor } from '../services/DoctorService';
import { PatientDetails } from '../types/DoctorTypes';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface DoctorUserModalProps {
    doctorId: string;
    onClose: () => void;
    onPatientCreated: (patientId: string) => void;
    showCreateUserForm: boolean;
    setShowCreateUserForm: React.Dispatch<React.SetStateAction<boolean>>;
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>;
    existingPatient?: PatientDetails | null;
    setExistingPatient?: React.Dispatch<React.SetStateAction<PatientDetails | null>>;
}

interface FormData {
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    gender: string;
    doctorId: string;
}

const DoctorUserModal: React.FC<DoctorUserModalProps> = ({
    doctorId,
    onClose,
    onPatientCreated,
    showCreateUserForm,
    setShowCreateUserForm,
    formData,
    setFormData,
    existingPatient,
    setExistingPatient
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckingUser, setIsCheckingUser] = useState(false);
    const [isUserVerified, setIsUserVerified] = useState(false);

    const validatePhoneNumber = (phone: string) => {
        const phoneRegex = /^[6-9]\d{9}$/;
        return phoneRegex.test(phone);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === 'mobile') {
            const numbersOnly = value.replace(/[^\d]/g, '');
            if (numbersOnly.length <= 10) {
                setFormData(prev => ({ ...prev, [name]: numbersOnly }));
            }
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (isLoading) return;

        if (!validatePhoneNumber(formData.mobile)) {
            toast.error('Please enter a valid 10-digit mobile number starting with 6-9');
            return;
        }
        setIsLoading(true);
        
        try {
            if (existingPatient) {
                await assignPatientToDoctor(existingPatient.id, doctorId);
                onPatientCreated(existingPatient.id);
            } else {
                const response = await createUser(formData);
                const newPatientId = response.data.id;
                await assignPatientToDoctor(newPatientId, doctorId);
                onPatientCreated(newPatientId);
            }
        } catch (error: any) {
            if (error.response?.status === 409) {
                toast.warning('This patient is already in your patient list');
            } else {
                toast.error('Failed to assign patient. Please try again.');
                console.error('Error assigning existing patient:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckExistingPatient = async (mobile: string) => {
        if (!validatePhoneNumber(mobile)) {
            toast.error('Please enter a valid 10-digit mobile number starting with 6-9');
            return;
        }

        setIsCheckingUser(true);
        setIsUserVerified(false);
        const currentMobile = formData.mobile;

        try {
            const response = await checkExistingPatient(mobile);
            if (response && response.data.user) {
                setExistingPatient?.(response.data.user);
                setFormData({
                    ...formData,
                    firstName: response.data.user.firstName,
                    lastName: response.data.user.lastName,
                    email: response.data.user.email,
                    gender: response.data.user.gender,
                    doctorId: doctorId,
                    mobile: currentMobile
                });
                toast.success('Patient found! Details populated.');
                setShowCreateUserForm(true);
                setIsUserVerified(true);
            } else {
                toast.info('No existing patient found. Please fill in all details to create a new patient.');
                setShowCreateUserForm(true);
                setExistingPatient?.(null);
                setFormData({
                    ...formData,
                    firstName: '',
                    lastName: '',
                    email: '',
                    doctorId: doctorId,
                    gender: '',
                    mobile: currentMobile
                });
            }
        } catch (error: any) {
            if (((error as any)?.response)?.status === 404) {
                setExistingPatient?.(null);
                setFormData({
                    ...formData,
                    firstName: '',
                    lastName: '',
                    email: '',
                    doctorId: doctorId,
                    gender: '',
                    mobile: currentMobile
                });
                setShowCreateUserForm(true);
                toast.info('No existing patient found. Please fill in all details to create a new patient.');
            } else {
                console.error('Error checking existing patient:', error);
                toast.error('Failed to check for existing patient. Please try again.');
            }
        } finally {
            setIsCheckingUser(false);
        }
    };


    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
            <ToastContainer />
            <div className="bg-white p-8 rounded-xl shadow-2xl w-[500px] relative">
                <h2 className="text-2xl font-bold mb-6 text-gray-800">
                    Add New Patient
                </h2>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="space-y-2">
                        <label className="text-sm font-semibold text-gray-700">Mobile Number</label>
                        <div className="flex gap-2">
                            <input
                                type="tel"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                className="flex-1 p-3 block rounded-lg border border-gray-300 shadow-sm 
                                         focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                placeholder="Enter 10-digit mobile number"
                                maxLength={10}
                                required
                            />
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleCheckExistingPatient(formData.mobile);
                                }}
                                disabled={!formData.mobile || isCheckingUser}
                                className={`px-4 py-2 rounded-md text-white transition-colors ${
                                    isCheckingUser 
                                        ? 'bg-gray-500 cursor-not-allowed' 
                                        : 'bg-darkGreen hover:bg-darkGreen-light'
                                }`}
                            >
                                {isCheckingUser ? 'Checking...' : 'Check User'}
                            </button>
                        </div>
                    </div>

                    {isUserVerified && (
                        <p className="text-sm text-green-600 mt-1">
                            Patient found: {formData.firstName} {formData.lastName}
                        </p>
                    )}

                    {showCreateUserForm && (
                        <>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="space-y-2">
                                    <label className="text-sm font-semibold text-gray-700">First Name</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        className={`p-3 block w-full rounded-lg border border-gray-300 shadow-sm 
                                                 focus:ring-2 focus:ring-blue-500 focus:border-transparent
                                                 ${existingPatient ? 'bg-gray-50' : ''}`}
                                        required
                                        disabled={existingPatient !== null}
                                    />
                                </div>
                                <div className="space-y-2">
                                    <label className="text-sm font-semibold text-gray-700">Last Name</label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        className={`p-3 block w-full rounded-lg border border-gray-300 shadow-sm 
                                                 focus:ring-2 focus:ring-blue-500 focus:border-transparent
                                                 ${existingPatient ? 'bg-gray-50' : ''}`}
                                        required
                                        disabled={existingPatient !== null}
                                    />
                                </div>
                            </div>

                            <div className="space-y-2">
                                <label className="text-sm font-semibold text-gray-700">Email Address</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`p-3 block w-full rounded-lg border border-gray-300 shadow-sm 
                                             focus:ring-2 focus:ring-blue-500 focus:border-transparent
                                             ${existingPatient ? 'bg-gray-50' : ''}`}
                                    placeholder="patient@example.com"
                                    required
                                    disabled={existingPatient !== null}
                                />
                            </div>

                            <div className="space-y-2">
                                <label className="text-sm font-semibold text-gray-700">Gender</label>
                                <div className="flex gap-4">
                                    <label className="inline-flex items-center">
                                        <input
                                            type="radio"
                                            name="gender"
                                            value="male"
                                            checked={formData.gender === 'male'}
                                            onChange={handleChange}
                                            className="form-radio text-blue-500"
                                            disabled={existingPatient !== null}
                                        />
                                        <span className={`ml-2 ${existingPatient ? 'text-gray-500' : ''}`}>Male</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input
                                            type="radio"
                                            name="gender"
                                            value="female"
                                            checked={formData.gender === 'female'}
                                            onChange={handleChange}
                                            className="form-radio text-blue-500"
                                            disabled={existingPatient !== null}
                                        />
                                        <span className={`ml-2 ${existingPatient ? 'text-gray-500' : ''}`}>Female</span>
                                    </label>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="flex justify-end space-x-3 pt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-6 py-3 bg-gray-100 text-gray-700 rounded-lg font-medium
                                     hover:bg-gray-200 transition-colors duration-200"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-6 py-3 bg-darkGreen text-white rounded-lg font-medium
                                     hover:bg-darkGreen transition-colors duration-200"
                            disabled={isLoading}
                        >
                            {existingPatient ? 'Assign Patient' : 'Create Patient'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DoctorUserModal;