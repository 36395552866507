import React from 'react';
import UserSidebar from '../../UserSidebar';

const UserReferral: React.FC = () => {
  return (
    <div className="flex h-screen bg-gradient-to-br from-blue-50 to-indigo-100 font-sans">
      <UserSidebar />
      <div className="flex-1 flex items-center justify-center overflow-y-auto p-8">
        <h1 className="text-5xl font-bold text-gray-700">Coming Soon</h1>
      </div>
    </div>
  );
};

export default UserReferral;