import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { forgotPasswordRequest } from '../../company/services/CompanyService';
import { ForgotPasswordFormData } from '../../company/types/CompanyAuthTypes';

const CompanyForgotPassword: React.FC = () => {
    const [formData, setFormData] = useState<ForgotPasswordFormData>({
        email: ''
    });
    const [, setError] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>('');
    const navigate = useNavigate();

    // Scroll to top whenever the component is mounted
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            await forgotPasswordRequest(formData.email);
            setError('');
            setModalMessage('Password reset link has been sent to your email.');
            setIsModalOpen(true); // Open the modal on successful response
        } catch (err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 404) {
                    setModalMessage('Email ID is not registered.');
                } else {
                    setModalMessage('Failed to send password reset email.');
                }
            } else {
                setModalMessage('Failed to send password reset email.');
            }
            setIsModalOpen(true); // Open the modal on error
        }
    };

    const handleOkClick = () => {
        setIsModalOpen(false);
        if (modalMessage === 'Password reset link has been sent to your email.') {
            navigate('/company/login'); // Redirect to login page on success
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl w-full px-4 sm:px-6 lg:px-8">
                <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10 mx-auto max-w-lg">
                    <h2 className="text-center text-4xl font-bold text-darkGreen mb-4">
                        Forgot Password
                    </h2>
                    <p className="text-center text-lg text-gray-600 mb-6">
                        Enter your registered email address to reset your password.
                    </p>
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div className="rounded-md shadow-sm space-y-4">
                            <div>
                                <label htmlFor="email-address" className="sr-only">Email address</label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                                    placeholder="Email address"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-md text-white bg-darkGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg"
                            >
                                Send Reset Email
                            </button>
                        </div>
                    </form>
                    <div className="text-sm text-center mt-6">
                        <a href="/company/login" className="font-medium text-blue-600 hover:text-blue-500">
                            Back to Login
                        </a>
                    </div>
                </div>
            </div>
            
            {/* Modal Popup */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg overflow-hidden shadow-lg max-w-sm w-full">
                        <div className="p-6">
                            <h3 className="text-xl font-semibold mb-4">
                                {modalMessage === 'Password reset link has been sent to your email.' ? 'Reset Email Sent' : 'Error'}
                            </h3>
                            <p className="text-gray-700 mb-6">
                                {modalMessage}
                            </p>
                            <button
                                onClick={handleOkClick}
                                className="w-full bg-darkGreen text-white py-2 px-4 rounded-md transition duration-300"
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompanyForgotPassword;