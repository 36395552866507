// features/doctor/components/DoctorSidebar.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';

const DoctorSidebar: React.FC = () => {
    return (
        <div className="hidden lg:block w-64 bg-white shadow-lg">
            <nav className="mt-6 space-y-2">
                <NavLink
                    to="/doctor/dashboard"
                    className={({ isActive }) =>
                        `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-indigo-50 text-indigo-900' : 'text-gray-600 hover:bg-indigo-50 hover:text-indigo-900'}`
                    }
                >
                    Dashboard
                </NavLink>
                <NavLink
                    to="/doctor/schedule"
                    className={({ isActive }) =>
                        `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-indigo-50 text-indigo-900' : 'text-gray-600 hover:bg-indigo-50 hover:text-indigo-900'}`
                    }
                >
                    Manage Appointments
                </NavLink>
                <NavLink
                    to="/doctor/manage-patients"
                    className={({ isActive }) =>
                        `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-indigo-50 text-indigo-900' : 'text-gray-600 hover:bg-indigo-50 hover:text-indigo-900'}`
                    }
                >
                    Manage Patients
                </NavLink>
                <NavLink
                  to="/doctor/profile"
                  className={({ isActive }) =>
                    `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-indigo-50 text-indigo-900' : 'text-gray-600 hover:bg-indigo-50 hover:text-indigo-900'}`
                  }
                  >
                    Manage Profile
                </NavLink>
                <NavLink
                  to="/doctor/manage-hospitals"
                  className={({ isActive }) =>
                    `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-indigo-50 text-indigo-900' : 'text-gray-600 hover:bg-indigo-50 hover:text-indigo-900'}`
                  }
              >
                  Manage Hospitals
              </NavLink>
                <NavLink
                    to="/auth/doctor/logout"
                    className={({ isActive }) =>
                        `block w-full text-left px-6 py-3 text-lg rounded-md transition-all duration-200 ${isActive ? 'bg-red-50 text-red-900' : 'text-red-600 hover:bg-red-50 hover:text-red-900'}`
                    }
                >
                    Logout
                </NavLink>
            </nav>
        </div>
    );
};

export default DoctorSidebar;