// DoctorForgotPassword.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { forgotPasswordRequest } from '../../doctor/services/DoctorService';
import { DoctorForgotPasswordFormData } from '../../doctor/types/DoctorTypes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const DoctorForgotPassword: React.FC = () => {
    const [formData, setFormData] = useState<DoctorForgotPasswordFormData>({ email: '' });
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setMessage(null);
        setError(null);
        setIsLoading(true);

        try {
            const response = await forgotPasswordRequest(formData.email);
            if (response.status === 200) {
                toast.success('Password reset email sent successfully. Please check your inbox.');
                setIsLoading(false);
                setTimeout(() => {
                    navigate('/doctor/login');
                }, 3000);
            }
        } catch (error: any) {
            setIsLoading(false);

            if (axios.isAxiosError(error)) {
                if (error.response?.status === 404) {
                    toast.error('This email is not registered. Please check your email address.');
                } else if (error.response) {
                    toast.error(error.response?.data.message || 'An unexpected error occurred. Please try again.');
                }
            } else {
                toast.error('An unexpected error occurred. Please try again.');
            }
            console.error('Error requesting password reset:', error);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <ToastContainer />
            <div className="max-w-7xl w-full px-4 sm:px-6 lg:px-8">
                <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10 mx-auto max-w-lg">
                    <h2 className="text-center text-3xl font-extrabold text-darkGreen">Forgot Password?</h2>
                    <p className="text-center text-lg text-gray-600 mb-6">
                        Enter your registered email address to reset your password.
                    </p>
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="rounded-md shadow-sm space-y-4">
                            <div>
                                <label htmlFor="email-address" className="sr-only">Email address</label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none rounded-md relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                                    placeholder="Email address"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-md text-white bg-darkGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg"
                                disabled={isLoading}
                            >
                                Send Reset Email
                            </button>
                        </div>
                    </form>
                    <div className="text-sm text-center mt-6">
                        <Link to="/auth/doctor/login" className="font-medium text-blue-600 hover:text-blue-500">
                            Back to Login
                        </Link>
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-700 bg-opacity-50">
                    <div className="bg-white rounded-lg p-8">
                        <p className="text-lg font-semibold text-gray-800">Sending reset link...</p>
                    </div>
                </div>
            )}
            {error && <p className="text-red-500 text-sm mt-2 text-center">{error}</p>}
            {message && <p className="text-green-500 text-sm mt-2 text-center">{message}</p>}
        </div>
    );
};

export default DoctorForgotPassword;