import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { registerDoctor } from '../../doctor/services/DoctorService';
import { DoctorRegisterFormData } from '../../doctor/types/DoctorTypes';

const DoctorRegister: React.FC = () => {
    const [formData, setFormData] = useState<DoctorRegisterFormData>({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        specialization: '',
    });
    const [, setError] = useState<string | null>(null);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAgreeToTerms(e.target.checked);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        // Check all required fields
        if (!formData.firstName || !formData.lastName || !formData.email || 
            !formData.password || !formData.confirmPassword || !formData.phoneNumber || 
            !formData.specialization) {
            toast.error('All fields are required');
            return;
        }

        // Check if passwords match
        if (formData.password !== formData.confirmPassword) {
            toast.error('Password and Confirm Password do not match.');
            return;
        }

        if (!agreeToTerms) {
            toast.error('You must agree to the Terms and Conditions and Privacy Policy.');
            return;
        }

        try {
            const response = await registerDoctor(formData);

            if (response.status === 201) {
                toast.success('Registration successful! Please verify your email.');
                sessionStorage.setItem('doctorEmail', formData.email);
                setTimeout(() => {
                    navigate('/auth/doctor/verify-otp');
                }, 2000);
            }
        } catch (error: any) {
            const status = error.response?.status;
            const message = error.response?.data?.message || 'Registration failed. Please try again later.';

            switch (status) {
                case 409:
                    toast.error('Email already registered, please login to access the YourNidra account');
                    break;
                case 422:
                    toast.error('All fields are required. Please check your input.');
                    break;
                case 500:
                    toast.error('Server error. The problem is on our side. Please try again later.');
                    break;
                default:
                    toast.error(message);
            }

            setError(message);
            console.error("Error during doctor registration:", error);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <ToastContainer />
            <div className="max-w-7xl w-full px-4 sm:px-6 lg:px-8">
                <div className="bg-white py-8 px-6 shadow rounded-lg sm:px-10 mx-auto max-w-lg">
                    <h2 className="text-center text-3xl font-extrabold text-darkGreen">Register</h2>
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                        <input type="hidden" name="remember" value="true" />
                        <div className="rounded-md shadow-sm space-y-4">
                            <div>
                                <label htmlFor="firstName" className="sr-only">First Name</label>
                                <input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    required
                                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="lastName" className="sr-only">Last Name</label>
                                <input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="email" className="sr-only">Email address</label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Email address"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="mobile" className="sr-only">Mobile Number</label>
                                <input
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    type="tel"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Mobile Number"
                                    value={formData.phoneNumber}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="specialization" className="sr-only">Specialization</label>
                                <input
                                    id="specialization"
                                    name="specialization"
                                    type="text"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Specialization"
                                    value={formData.specialization}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">Password</label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="confirmPassword" className="sr-only">Confirm Password</label>
                                <input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    className="appearance-none rounded-b-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                    placeholder="Confirm Password"
                                    value={formData.confirmPassword}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    checked={agreeToTerms}
                                    onChange={handleTermsChange}
                                    className="form-checkbox h-4 w-4 text-darkGreen transition duration-150 ease-in-out"
                                />
                                <span className="ml-2 text-sm text-gray-600">
                                    I agree to the{" "}
                                    <Link to="/terms-of-use" className="text-blue-600 hover:text-blue-800">Terms and Conditions</Link>{" "}
                                    and{" "}
                                    <Link to="/privacy-policy" className="text-blue-600 hover:text-blue-800">Privacy Policy</Link>{" "}
                                    of YourNidra.
                                </span>
                            </label>
                        </div>
                        <div>
                            <button
                                type="submit"
                                disabled={!agreeToTerms}
                                className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-md text-white bg-darkGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg"
                            >
                                Register
                            </button>
                        </div>
                    </form>
                    <div className="text-sm text-center mt-6">
                        <Link to="/auth/doctor/login" className="font-medium text-blue-600 hover:text-blue-500">
                            Already have an account? Login here
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DoctorRegister;