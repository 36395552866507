// features/doctor/components/DoctorPatientList.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { fetchDoctorPatients } from '../services/DoctorService';
import { PatientDetails } from '../types/DoctorTypes';
import DoctorUserModal from './DoctorUserModal';
import DoctorLayout from './DoctorLayout';
import { toast } from 'react-toastify';

type SleepStudyStatus = 'not_scheduled' | 'Request for Schedule' | 'Scheduled' | 'Completed' | 'Cancelled';

const statusStyles: Record<SleepStudyStatus, string> = {
    'not_scheduled': 'bg-gray-100 text-gray-800',
    'Request for Schedule': 'bg-blue-100 text-blue-800',
    'Scheduled': 'bg-yellow-100 text-yellow-800',
    'Completed': 'bg-green-100 text-green-800',
    'Cancelled': 'bg-red-100 text-red-800'
};

const DoctorPatientList: React.FC = () => {
    const [patients, setPatients] = useState<PatientDetails[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [, setShowCreateUserForm] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        mobile: '',
        gender: '',
        doctorId: '',
    });
    const [existingPatient, setExistingPatient] = useState<PatientDetails | null>(null);
    const navigate = useNavigate();
    const storedDoctorId = localStorage.getItem('doctorId') || '';

    useEffect(() => {
        if(!storedDoctorId) {
            return;
        }

        const fetchPatients = async () => {
            try {
                setLoading(true);
                const fetchedPatients = await fetchDoctorPatients(storedDoctorId);
                setPatients(fetchedPatients);
            } catch (error) {
                setError('Failed to fetch patients.');
                console.error("Error fetching patients:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPatients();
    }, [storedDoctorId]);

    const filteredPatients = patients.filter(patient =>
        patient.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        patient.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        patient._id.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleAddPatient = () => {
        
        setFormData({
            email: '',
            firstName: '',
            lastName: '',
            mobile: '',
            gender: '',
            doctorId: storedDoctorId,
        });
        setShowCreateUserForm(true);
        setIsModalOpen(true);
    };

    const handlePatientCreated = async (patientId: string) => {
        try {
            const updatedPatients = await fetchDoctorPatients(storedDoctorId);
            setPatients(updatedPatients);
            setIsModalOpen(false);
            toast.success('Patient successfully added!');
        } catch (error) {
            console.error('Error updating patient list:', error);
            toast.error('Failed to update patient list');
        }
    };

    const getSleepStudyStatusBadge = (status: string) => {
        const validStatus = (status as SleepStudyStatus) || 'not_scheduled';
        return (
            <span className={`px-2 py-1 rounded-full text-xs font-medium ${statusStyles[validStatus]}`}>
                {validStatus === 'not_scheduled' ? 'Not Scheduled' : validStatus}
            </span>
        );
    };

    if (loading) return <div>Loading patients...</div>;
    if (error) return <div>Failed to load patients. Please try again later.</div>;

    return (
        <DoctorLayout>
            <div className="p-6 bg-white rounded-lg shadow-md">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-800">Manage Patients</h2>
                    <button 
                        onClick={handleAddPatient} 
                        className="bg-darkGreen hover:bg-darkGreen-light text-white font-bold py-2 px-4 rounded transition-colors"
                    >
                        Add Patient
                    </button>
                </div>

                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Search patients by name"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="border border-gray-300 rounded-lg px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>

                {/* Patient List Table */}
                {filteredPatients.length > 0 ? (
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Latest Quiz Risk Level</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sleep Study Status</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredPatients.map(patient => (
                                <tr key={patient._id} onClick={() => navigate(`/doctor/patient/${patient._id}`)} className="cursor-pointer hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-900">{patient.firstName} {patient.lastName}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {patient.quizData && patient.quizData.length > 0 ? (
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                patient.quizData[0].riskLevel === 'Low Risk' ? 'bg-green-100 text-green-800' :
                                                patient.quizData[0].riskLevel === 'Medium Risk' ? 'bg-yellow-100 text-yellow-800' :
                                                'bg-red-100 text-red-800'
                                            }`}>
                                                {patient.quizData[0].riskLevel}
                                            </span>
                                        ) : (
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                                                No Quiz Taken
                                            </span>
                                        )}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {getSleepStudyStatusBadge(patient.sleepStudyData[0]?.status || 'not_scheduled')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="text-center py-8">
                        <p className="text-gray-500 text-lg">No patients found.</p>
                        <p className="text-gray-400 mt-2">Add new patients using the button above.</p>
                    </div>
                )}

                {/* Modal */}
                {isModalOpen && (
                    <DoctorUserModal
                        doctorId={storedDoctorId}
                        onClose={() => {
                            setIsModalOpen(false);
                            setShowCreateUserForm(false);
                            setExistingPatient(null);
                        }}
                        onPatientCreated={handlePatientCreated}
                        showCreateUserForm={true}
                        setShowCreateUserForm={setShowCreateUserForm}
                        formData={formData}
                        setFormData={setFormData}
                        existingPatient={existingPatient}
                        setExistingPatient={setExistingPatient}
                    />
                )}
            </div>
        </DoctorLayout>
    );
};

export default DoctorPatientList;