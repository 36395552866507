// Define API interaction logic for company features
import axios from 'axios'; // Or your preferred HTTP client
import { CompanyAnalytics, CompanyData } from '../types/CompanyTypes';
import { LoginFormData, RegisterFormData } from '../types/CompanyAuthTypes';
import { forgotPassword, login, register, resetPassword } from '../../../api/auth/CompanyAuthApi';

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const loginCompany = async (formData: LoginFormData) => {
    try {
        const response = await login(formData);
        
        // Check if response data exists before accessing it
        if (response && response.data) {
            console.log("Response data:", response.data);
            
            const { token, company } = response.data;
            // Perform localStorage operations or other post-API logic
            localStorage.setItem('companyToken', token);
            localStorage.setItem('companyId', company._id);
            localStorage.setItem('isCompanyLoggedIn', 'true');
            localStorage.setItem('companyEmail', company.companyEmail);
            localStorage.setItem('companyPhoneNumber', company.phoneNumber);
            localStorage.setItem('companyName', `${company.companyName}`);
        } else {
            console.error("Response data is undefined or invalid");
        }

        return response;
    } catch (error: any) {
        // Log the error to the console
        console.error("Error during login:", error);

        if (error.response) {
            // Specific error from API
            console.error("Error response data:", error.response.data);
        } else {
            // General error
            console.error("An unexpected error occurred.");
        }
        throw error;  // Re-throw the error to handle it in the calling function if needed
    }
};

export const registerCompany = async (formData: RegisterFormData) => {
    const response = await register(formData);
    return response;
};


export const forgotPasswordRequest = async (email: string) => {
    return forgotPassword(email);
};

export const requestPasswordReset = async (token: string, password: string) => {
    return resetPassword(token, password);
};
// Get company analytics data
export const fetchCompanyAnalytics = async (token: string): Promise<CompanyAnalytics> => {
    try {
        const response = await axios.get<CompanyAnalytics>(`${baseURL}/company/dashboard`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data; 
    } catch (error) {
        console.error('Error fetching company analytics:', error);
        throw error; 
    }
};

// Get company data
export const fetchCompanyData = async (token: string): Promise<CompanyData> => {
    try {
        const response = await axios.get<CompanyData>(`${baseURL}/company/profile`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching company data:', error);
        throw error;
    }
};

// Example for updating company profile (add more as needed)
export const updateCompanyProfile = async (token: string, companyData: CompanyData): Promise<void> => {
    try {
        await axios.put(`${baseURL}/company/profile`, companyData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (error) {
        console.error('Error updating company profile:', error);
        throw error;
    }
};