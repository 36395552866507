// features/doctor/components/ManageHospitals.tsx
import React, { useState, useEffect } from 'react';
import DoctorLayout from './DoctorLayout';
import {
    fetchDoctorHospitals,
    fetchAllHospitals,
    joinHospital,
    unassignHospital
} from '../services/DoctorService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrash } from 'react-icons/fa';

interface HospitalType {
    _id: string;
    name: string;
    address: string;
    contactNumber: string;
    email: string;
}

const DoctorHospitals: React.FC = () => {
    const [linkedHospitals, setLinkedHospitals] = useState<HospitalType[]>([]);
    const [allHospitals, setAllHospitals] = useState<HospitalType[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedHospital, setSelectedHospital] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [showUnassignModal, setShowUnassignModal] = useState(false);
    const [hospitalToUnassign, setHospitalToUnassign] = useState<string | null>(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const doctorId = localStorage.getItem('doctorId');
            if (!doctorId) {
                throw new Error('Doctor ID not found');
            }

            const [doctorHospitalsData, allHospitalsData] = await Promise.all([
                fetchDoctorHospitals(doctorId),
                fetchAllHospitals()
            ]);

            setLinkedHospitals(doctorHospitalsData);
            setAllHospitals(allHospitalsData);
            setError(null);
        } catch (error: any) {
            setError(error.message || 'Failed to load hospitals');
            console.error("Failed to fetch hospitals data", error);
        } finally {
            setLoading(false);
        }
    };

    const handleJoinHospital = async () => {
        if (!selectedHospital) {
            toast.error('Please select a hospital');
            return;
        }

        try {
            const doctorId = localStorage.getItem('doctorId');
            if (!doctorId) {
                throw new Error('Doctor ID not found');
            }

            await joinHospital(doctorId, selectedHospital);
            toast.success('Successfully joined hospital');
            setShowModal(false);
            setSelectedHospital('');
            fetchData(); // Refresh the list
        } catch (error: any) {
            toast.error(error.message || 'Failed to join hospital');
            console.error('Error joining hospital:', error);
        }
    };

    const handleUnassignHospital = async (hospitalId: string) => {
        setHospitalToUnassign(hospitalId);
        setShowUnassignModal(true);
    };

    const confirmUnassign = async () => {
        if (!hospitalToUnassign) return;
        
        try {
            const doctorId = localStorage.getItem('doctorId');
            if (!doctorId) {
                throw new Error('Doctor ID not found');
            }

            await unassignHospital(doctorId, hospitalToUnassign);
            toast.success('Successfully unassigned from hospital');
            fetchData(); // Refresh the list
        } catch (error: any) {
            toast.error(error.message || 'Failed to unassign from hospital');
            console.error('Error unassigning from hospital:', error);
        } finally {
            setShowUnassignModal(false);
            setHospitalToUnassign(null);
        }
    };

    const getAvailableHospitals = () => {
        return allHospitals.filter(hospital => 
            !linkedHospitals.some(linked => linked._id === hospital._id)
        );
    };

    if (loading) {
        return (
            <DoctorLayout>
                <div>Loading...</div>
            </DoctorLayout>
        );
    }

    if (error) {
        return (
            <DoctorLayout>
                <div>Error: {error}</div>
            </DoctorLayout>
        );
    }

    return (
        <DoctorLayout>
            <ToastContainer />
            <div className="p-6 bg-white rounded-lg shadow-md">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-800">My Hospitals</h2>
                    <button
                        onClick={() => setShowModal(true)}
                        className="bg-darkGreen hover:bg-darkGreen-light text-white font-bold py-2 px-4 rounded transition-colors"
                    >
                        Join Hospital
                    </button>
                </div>

                {linkedHospitals.length > 0 ? (
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contact Number</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {linkedHospitals.map((hospital) => (
                                <tr key={hospital._id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{hospital.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{hospital.address}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{hospital.contactNumber}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{hospital.email}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <button
                                            onClick={() => handleUnassignHospital(hospital._id)}
                                            className="text-red-600 hover:text-red-800 transition-colors"
                                            title="Unassign from Hospital"
                                        >
                                            <FaTrash className="w-4 h-4" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-gray-500 mt-4">No hospitals joined yet.</p>
                )}

                {showModal && (
                    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg shadow-lg relative w-full max-w-md">
                            <h3 className="text-xl font-bold mb-4">Join Hospital</h3>
                            <select
                                value={selectedHospital}
                                onChange={(e) => setSelectedHospital(e.target.value)}
                                className="w-full p-2 border rounded mb-4"
                            >
                                <option value="">Select a hospital</option>
                                {getAvailableHospitals().map((hospital) => (
                                    <option key={hospital._id} value={hospital._id}>
                                        {hospital.name}
                                    </option>
                                ))}
                            </select>
                            <div className="flex justify-end space-x-2">
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleJoinHospital}
                                    className="px-4 py-2 bg-darkGreen text-white rounded hover:bg-darkGreen-light transition-colors"
                                >
                                    Join Hospital
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Unassign Confirmation Modal */}
                {showUnassignModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                            <h3 className="text-lg font-semibold mb-4">Confirm Unassign</h3>
                            <p className="text-gray-600 mb-6">
                                Are you sure you want to unassign from this hospital?
                            </p>
                            <div className="flex justify-end gap-2">
                                <button
                                    onClick={() => {
                                        setShowUnassignModal(false);
                                        setHospitalToUnassign(null);
                                    }}
                                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={confirmUnassign}
                                    className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md transition-colors"
                                >
                                    Unassign
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </DoctorLayout>
    );
};

export default DoctorHospitals;