import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserSidebar from '../../UserSidebar';
import { fetchQuizzes, fetchQuizDetails } from '../services/UserQuizService';
import { QuizData } from '../types/userQuizTypes';

const UserQuestionnaire: React.FC = () => {
    const navigate = useNavigate();
    const [quizzes, setQuizzes] = useState<QuizData[]>([]);
    const [loading, setLoading] = useState(true);
    const [, setError] = useState<string | null>(null);
    const [selectedQuiz, setSelectedQuiz] = useState<QuizData | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(1); // Page state for pagination
    const quizzesPerPage = 5; // Number of quizzes per page

    useEffect(() => {
        const userId = localStorage.getItem('id');
        const token = localStorage.getItem('token');

        if (userId && token) {
            loadQuizzes(userId, token);
        }
    }, []);

    const loadQuizzes = async (userId: string, token: string) => {
        try {
            setLoading(true);
            const fetchedQuizzes = await fetchQuizzes(userId, token);
            if (Array.isArray(fetchedQuizzes)) {
                setQuizzes(fetchedQuizzes); // Set quizzes only if it's an array
            } else {
                setError('Unexpected data format');
            }
        } catch (err) {
            setError('Failed to load quizzes');
        } finally {
            setLoading(false);
        }
    };

    const handleSeeResultClick = async (quizId: string) => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('User is not authenticated');
            return;
        }

        try {
            const quizData = await fetchQuizDetails(quizId, token);
            setSelectedQuiz(quizData);
            setShowModal(true);
        } catch (error) {
            setError('Failed to load quiz details');
        }
    };

    const handleTakeQuizClick = () => {
        navigate('/quiz');
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const getRiskLevelColor = (riskLevel: string) => {
        switch (riskLevel) {
            case 'Low Risk':
                return 'border-green-500';
            case 'Medium Risk':
                return 'border-yellow-500';
            case 'High Risk':
                return 'border-red-500';
            default:
                return 'border-gray-500';
        }
    };

    // Pagination logic
    const startIndex = (page - 1) * quizzesPerPage;
    const paginatedQuizzes = quizzes.slice(startIndex, startIndex + quizzesPerPage);
    const totalPages = Math.ceil(quizzes.length / quizzesPerPage);

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen bg-gray-50 font-sans">
            <UserSidebar />
            <div className="flex-1 overflow-y-auto p-8 lg:p-8">
                <div className="flex flex-col lg:flex-row justify-between items-center mb-6">
                    <div className="text-center lg:text-left mb-4 lg:mb-0">
                        <h2 className="text-2xl lg:text-3xl font-bold mb-2">YourNidra Self Assessments</h2>
                        <p className="text-sm lg:text-base text-gray-600">See your sleep self-assessments over time</p>
                    </div>
                    <button
                        onClick={handleTakeQuizClick}
                        className="bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-6 rounded-lg shadow-md"
                    >
                        Take the Quiz
                    </button>
                </div>

                {loading ? (
                    <div className="flex justify-center items-center">
                        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-darkGreen" role="status"></div>
                    </div>
                ) : quizzes.length > 0 ? (
                    <div className="space-y-4 lg:space-y-6">
                        {paginatedQuizzes
                            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                            .map((quiz) => (
                                <div
                                    key={quiz.id}
                                    className={`relative bg-white rounded-lg shadow-md p-4 lg:p-6 border-l-4 ${getRiskLevelColor(quiz.riskLevel)}`}
                                >
                                    <p className="absolute top-4 right-4 text-sm text-gray-600">
                                        {new Date(quiz.createdAt).toLocaleDateString()}
                                    </p>

                                    <div className="flex flex-col lg:flex-row justify-between items-start">
                                        <div className="mb-4 lg:mb-0">
                                            <h3 className="text-lg lg:text-xl font-semibold">Self Assessment Quiz</h3>
                                            <p className="text-gray-700 mt-2">Risk Level: {quiz.riskLevel}</p>
                                        </div>
                                    </div>

                                    <button
                                        onClick={() => handleSeeResultClick(quiz.id)}
                                        className="absolute bottom-4 right-4 bg-darkGreen hover:bg-darkGreen text-white font-bold py-1 px-3 rounded-lg text-sm inline-flex items-center"
                                    >
                                        <span>See Result</span>
                                    </button>
                                </div>
                            ))}

                        {/* Pagination controls */}
                        <div className="flex flex-col lg:flex-row justify-between items-center mt-4 space-y-4 lg:space-y-0">
                            {page > 1 && (
                                <button
                                    onClick={handlePreviousPage}
                                    className="bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                >
                                    Previous
                                </button>
                            )}
                            {page < totalPages && (
                                <button
                                    onClick={handleNextPage}
                                    className="bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                >
                                    Next
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <p className="text-center text-gray-600">No self-assessments completed</p>
                )}
            </div>

            {/* Modal for viewing quiz details */}
            {showModal && selectedQuiz && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                    <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-md lg:max-w-[90vw] h-auto max-h-[90vh] overflow-y-auto relative">
                        {/* Modal Header */}
                        <div className="flex justify-between items-start mb-4">
                            <h2 className="text-xl lg:text-2xl font-bold">Self Assessment Results</h2>
                            <button
                                className="bg-darkGreen text-white font-bold py-1 px-3 rounded"
                                onClick={handleCloseModal}
                            >
                                Close
                            </button>
                        </div>

                        <p className="text-sm text-gray-600 mb-4">
                            Completed on: {new Date(selectedQuiz.createdAt).toLocaleDateString()}
                        </p>

                        {/* Quiz Details Section */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                            {/* Age */}
                            <div>
                                <label className="block font-semibold mb-2">Age</label>
                                <input
                                    type="text"
                                    value={selectedQuiz.age || 'N/A'}
                                    className="w-full px-3 py-2 border rounded"
                                    readOnly
                                />
                            </div>
                            {/* BMI */}
                            <div>
                                <label className="block font-semibold mb-2">BMI</label>
                                <input
                                    type="text"
                                    value={selectedQuiz.bmi?.toFixed(2) || 'N/A'}
                                    className="w-full px-3 py-2 border rounded"
                                    readOnly
                                />
                            </div>
                            {/* Blood Pressure */}
                            <div>
                                <label className="block font-semibold mb-2">Blood Pressure</label>
                                <input
                                    type="text"
                                    value={selectedQuiz.bp === 'yes' ? 'Yes' : 'No'}
                                    className="w-full px-3 py-2 border rounded"
                                    readOnly
                                />
                            </div>
                            {/* Collar Size */}
                            <div>
                                <label className="block font-semibold mb-2">Collar Size</label>
                                <input
                                    type="text"
                                    value={selectedQuiz.collar === 'yes' ? 'Yes' : 'No'}
                                    className="w-full px-3 py-2 border rounded"
                                    readOnly
                                />
                            </div>
                            {/* Gender */}
                            <div>
                                <label className="block font-semibold mb-2">Gender</label>
                                <input
                                    type="text"
                                    value={selectedQuiz.gender ? selectedQuiz.gender.charAt(0).toUpperCase() + selectedQuiz.gender.slice(1) : 'N/A'}
                                    className="w-full px-3 py-2 border rounded"
                                    readOnly
                                />
                            </div>
                            {/* Height */}
                            <div>
                                <label className="block font-semibold mb-2">Height (cm)</label>
                                <input
                                    type="text"
                                    value={selectedQuiz.height || 'N/A'}
                                    className="w-full px-3 py-2 border rounded"
                                    readOnly
                                />
                            </div>
                            {/* Weight */}
                            <div>
                                <label className="block font-semibold mb-2">Weight (kg)</label>
                                <input
                                    type="text"
                                    value={selectedQuiz.weight || 'N/A'}
                                    className="w-full px-3 py-2 border rounded"
                                    readOnly
                                />
                            </div>
                            {/* Snoring */}
                            <div>
                                <label className="block font-semibold mb-2">Snoring</label>
                                <input
                                    type="text"
                                    value={selectedQuiz.snore === 'yes' ? 'Yes' : 'No'}
                                    className="w-full px-3 py-2 border rounded"
                                    readOnly
                                />
                            </div>
                            {/* Tiredness */}
                            <div>
                                <label className="block font-semibold mb-2">Tiredness</label>
                                <input
                                    type="text"
                                    value={selectedQuiz.tired === 'yes' ? 'Yes' : 'No'}
                                    className="w-full px-3 py-2 border rounded"
                                    readOnly
                                />
                            </div>
                            {/* Observed Breathing Issues */}
                            <div>
                                <label className="block font-semibold mb-2">Observed Breathing Issues</label>
                                <input
                                    type="text"
                                    value={selectedQuiz.observed === 'yes' ? 'Yes' : 'No'}
                                    className="w-full px-3 py-2 border rounded"
                                    readOnly
                                />
                            </div>
                            {/* Risk Level */}
                            <div>
                                <label className="block font-semibold mb-2">Risk Level</label>
                                <input
                                    type="text"
                                    value={selectedQuiz.riskLevel || 'N/A'}
                                    className="w-full px-3 py-2 border rounded"
                                    readOnly
                                />
                            </div>
                        </div>

                        {/* Recommendations Based on Risk Level */}
                        <div className="mt-8">
                            {selectedQuiz.riskLevel === 'High Risk' && (
                                <div className="bg-red-100 p-4 rounded">
                                    <h3 className="text-xl font-bold mb-2">Understanding Your Score: High Risk</h3>
                                    <p>
                                        A high risk score suggests that you might have sleep apnea, which can have serious health implications if left untreated.
                                    </p>
                                    <h4 className="font-bold mt-4">Next Steps:</h4>
                                    <ul className="list-disc ml-4">
                                        <li>Immediate Consultation: It is crucial to discuss your result with a healthcare professional as soon as possible. We can assist you in scheduling an appointment.</li>
                                        <li>Sleep Study: Your doctor may recommend a sleep study to diagnose sleep apnea definitively.</li>
                                        <li>Lifestyle Changes: Managing your weight, avoiding alcohol before bedtime, and sleeping on your side can help alleviate some symptoms.</li>
                                    </ul>
                                    <p className="mt-4">
                                        A YourNidra representative will contact you to guide you on the potential next steps to help you mitigate the risks and manage the risk of sleep apnea.
                                    </p>
                                </div>
                            )}
                            {selectedQuiz.riskLevel === 'Medium Risk' && (
                                <div className="bg-yellow-100 p-4 rounded">
                                    <h3 className="text-xl font-bold mb-2">Understanding Your Score: Medium Risk</h3>
                                    <p>
                                        A medium risk score means you may have sleep apnea and should consider further evaluation.
                                    </p>
                                    <h4 className="font-bold mt-4">Next Steps:</h4>
                                    <ul className="list-disc ml-4">
                                        <li>Consult with a Specialist: We recommend discussing your result with a healthcare professional for a comprehensive evaluation.</li>
                                        <li>Lifestyle Adjustments: Maintaining a healthy weight, avoiding alcohol before bedtime, and sleeping on your side can help alleviate symptoms.</li>
                                        <li>Monitor Your Symptoms: Keep an eye on any changes in your sleep patterns or overall health.</li>
                                    </ul>
                                    <p className="mt-4">
                                        A YourNidra representative will contact you to guide you on the potential next steps to help you mitigate the risks and manage the risk of sleep apnea.
                                    </p>
                                </div>
                            )}
                            {selectedQuiz.riskLevel === 'Low Risk' && (
                                <div className="bg-green-100 p-4 rounded">
                                    <h3 className="text-xl font-bold mb-2">Understanding Your Score: Low Risk</h3>
                                    <p>
                                        Your low-risk score suggests that you are unlikely to have sleep apnea. However, maintaining good sleep hygiene and monitoring your health is important.
                                    </p>
                                    <h4 className="font-bold mt-4">Next Steps:</h4>
                                    <ul className="list-disc ml-4">
                                        <li>Maintain a Healthy Lifestyle: Regular exercise, a balanced diet, and avoiding alcohol before bedtime can support good sleep quality.</li>
                                        <li>Monitor Symptoms: If you notice increased snoring, daytime fatigue, or other symptoms, consider re-evaluating your sleep health.</li>
                                    </ul>
                                    <p className="mt-4">
                                        Sleep apnea can be triggered at any age – please take the quiz every 3 months to track any changes with age.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserQuestionnaire;