// features/doctor/components/DoctorLayout.tsx (New Layout Component)
import React from 'react';
import DoctorSidebar from '../DoctorSidebar';

interface DoctorLayoutProps {
    children: React.ReactNode;
}

const DoctorLayout: React.FC<DoctorLayoutProps> = ({ children }) => {
    return (
        <div className="flex h-screen bg-gray-100 font-sans">
            <DoctorSidebar />
            <div className="flex-1 overflow-y-auto p-8">
                {children}
            </div>
        </div>
    );
};

export default DoctorLayout;