// src/features/profile/services/profileService.ts
import { fetchProfileData, updateProfile } from '../../../../api/user/ProfileApi';
import { UserProfileType } from '../types/UserProfileTypes';

export const loadProfile = async (userId: string, token: string): Promise<UserProfileType> => {
  const response = await fetchProfileData(userId, token);
  return response.data.user;
};

export const saveProfile = async (userId: string, profileData: UserProfileType, token: string) => {
  await updateProfile(userId, profileData, token);
};