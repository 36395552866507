import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import blogEntries from './BlogEntries.json';
import { BlogEntry } from '../types/BlogTypes';
import BlogCard from './BlogCard';

const Blogs: React.FC = () => {
  const [blogs, setBlogs] = useState<BlogEntry[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setBlogs(blogEntries);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 py-20">
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-12 text-center">Know more about Sleep and Sleep Disorders</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {blogs.map((blog) => (
            <Link to={`/blog/${blog.id}`} key={blog.id} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-200">
              <BlogCard title={blog.title} image={blog.image} link={`/blog/${blog.id}`} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;