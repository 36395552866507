// features/doctor/components/DoctorPatientRecord.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    fetchPatientDetails,
    orderSleepStudy,
    requestSelfAssessment,
    requestConsultation,
    unassignPatient
} from '../services/DoctorService';
import { PatientDetails } from '../types/DoctorTypes';
import { format } from 'date-fns';
import DoctorLayout from './DoctorLayout';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye } from 'react-icons/fa';
import QuizDetailsModal from './QuizDetailsModal';

type SleepStudyStatus = 'not_scheduled' | 'Request for Schedule' | 'Scheduled' | 'Completed' | 'Cancelled';

const statusStyles: Record<SleepStudyStatus, string> = {
    'not_scheduled': 'bg-gray-100 text-gray-800',
    'Request for Schedule': 'bg-blue-100 text-blue-800',
    'Scheduled': 'bg-yellow-100 text-yellow-800',
    'Completed': 'bg-green-100 text-green-800',
    'Cancelled': 'bg-red-100 text-red-800'
};

const DoctorPatientRecord: React.FC = () => {
    const { patientId } = useParams<{ patientId: string }>();
    const [patientDetails, setPatientDetails] = useState<PatientDetails | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const doctorId = localStorage.getItem('doctorId') || '';

    const sleepStudyTypes = [
        { id: 'Type 1 - Full Polysomnography', name: 'Type 1 - Full Polysomnography' },
        { id: 'Type 2 - Limited Channel PSG', name: 'Type 2 - Limited Channel PSG' },
        { id: 'Type 3 - Home Sleep Test', name: 'Type 3 - Home Sleep Test' },
        { id: 'Type 4 - Continuous Single Bio-parameters', name: 'Type 4 - Continuous Single Bio-parameters' }
    ];

    const [selectedSleepStudyType, setSelectedSleepStudyType] = useState<string>('');
    const [orderingStudy, setOrderingStudy] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState<any>(null);
    const [showQuizModal, setShowQuizModal] = useState(false);
    const [openSection, setOpenSection] = useState({
        selfAssessments: true,
        sleepStudies: true
    });
    const [showUnassignModal, setShowUnassignModal] = useState(false);

    const toggleSection = (section: keyof typeof openSection) => {
        setOpenSection(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    useEffect(() => {
        const fetchPatientData = async () => {
            if (!patientId) {
                setError("Patient ID is missing");
                setLoading(false);
                return;
            }
            try {
                setLoading(true);
                const details = await fetchPatientDetails(patientId);
                setPatientDetails(details);
                setError(null); // Clear error if data fetches successfully
            } catch (err) {
                setError("Failed to fetch patient details.");
                console.error(err);

            } finally {
                setLoading(false)
            }
        }
        fetchPatientData()
    }, [patientId]);

    const handleOrderSleepStudy = async () => {
        if (!selectedSleepStudyType) {
            toast.error('Please select a sleep study type');
            return;
        }

        setOrderingStudy(true);
        try {
            const doctorId = localStorage.getItem('doctorId');
            if (!doctorId || !patientDetails) {
                toast.error('Missing required information');
                return;
            }

            const response = await orderSleepStudy({
                doctorId,
                patientId: patientDetails._id,
                sleepStudyType: selectedSleepStudyType
            });

            if (response.status === 201) {
                toast.success('Sleep study ordered successfully. Notifications sent to the patient and YourNidra team.');
                setSelectedSleepStudyType('');
                const details = await fetchPatientDetails(patientId!);
                setPatientDetails(details);
            }
        } catch (error: any) {
            console.error('Error ordering sleep study:', error);
            toast.error(error.response?.data?.message || 'Failed to order sleep study');
        } finally {
            setOrderingStudy(false);
        }
    };

    const handleRequestQuiz = async () => {
        try {
            const res = await requestSelfAssessment(doctorId, patientId!);
            if (res.status === 200) {
                toast.success('Assessment request sent to patient via email and WhatsApp');
            } else {
                toast.error(res.data.message || 'Failed to send assessment request');
            }
            // Refresh patient details
            const details = await fetchPatientDetails(patientId!);
            setPatientDetails(details);
        } catch (error: any) {
            console.error('Error requesting quiz:', error);
            toast.error(error.response?.data?.message || 'Failed to send assessment request');
        }
    };

    const handleRequestConsultation = async () => {
        try {
            const res = await requestConsultation(doctorId, patientId!);
            if (res.status === 200) {
                toast.success('Consultation request sent successfully!');
            } else {
                toast.error(res.data.message || 'Failed to send consultation request');
            }

            fetchPatientDetails(patientId!).then((details) => setPatientDetails(details));
        } catch (error) {
            console.error('Failed to request consultation:', error);
            alert('Failed to request consultation. Please try again.');
        }
    };

    const handleUnassignPatient = async () => {
        try {
            const res = await unassignPatient(doctorId, patientId!);
            if (res.status === 200) {
                toast.success('Patient unassigned successfully.');
                navigate('/doctor/manage-patients');
            } else {
                toast.error(res.data.message || 'Failed to unassign patient. Please try again.');
            }
        } catch (error) {
            console.error('Error unassigning patient:', error);
            toast.error('Failed to unassign patient. Please try again.');
        }
    };

    if (loading) return <div>Loading patient record...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!patientDetails) return <div>Patient not found.</div>;


    const formatDate = (dateString: string | undefined) => {
        if (!dateString) return 'N/A';
        return format(new Date(dateString), 'MMM dd, yyyy');
    }

    const displayOptional = (value: string | null | undefined) => {
        return value || 'N/A';
    };

      // Add status badges
        const getSleepStudyStatusBadge = (status: string) => {
        const validStatus = (status as SleepStudyStatus) || 'not_scheduled';
        return (
            <span className={`px-2 py-1 rounded-full text-sm font-medium ${statusStyles[validStatus]}`}>
               {validStatus === 'not_scheduled' ? 'Not Scheduled' : validStatus}
            </span>
        );
    };

    const capitalizeFirstLetter = (str: string | null | undefined): string => {
        if (!str) return 'N/A';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return (
        <DoctorLayout>
            <ToastContainer />
            <div className="p-6 bg-white rounded-lg shadow-md max-w-4xl mx-auto">
                <h2 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-4">Patient Record</h2>
                {/* Patient Information Section */}
                <div className="grid grid-cols-2 gap-6 mb-8">
                    <div className="space-y-4">
                        <div>
                            <label className="text-sm text-gray-600">Name</label>
                            <p className="font-medium text-gray-800">
                                {patientDetails.firstName} {patientDetails.lastName}
                            </p>
                        </div>
                        <div>
                            <label className="text-sm text-gray-600">Email</label>
                            <p className="font-medium text-gray-800">{patientDetails.email}</p>
                        </div>
                        <div>
                            <label className="text-sm text-gray-600">Phone Number</label>
                             <p className="font-medium text-gray-800">{displayOptional(patientDetails.phoneNumber)}</p>
                         </div>
                     </div>
                    <div className="space-y-4">
                        <div>
                            <label className="text-sm text-gray-600">Date of Birth</label>
                            <p className="font-medium text-gray-800">{formatDate(patientDetails.dateOfBirth)}</p>
                        </div>
                        <div>
                            <label className="text-sm text-gray-600">Gender</label>
                             <p className="font-medium text-gray-800">{capitalizeFirstLetter(patientDetails.gender)}</p>
                         </div>
                         <div>
                            <label className="text-sm text-gray-600">Sleep Study Status</label>
                            <p className="font-medium mt-1">
                             {getSleepStudyStatusBadge(patientDetails.sleepStudyData[0]?.status || 'not_scheduled')}
                                 </p>
                         </div>
                      </div>
                </div>


                  {/* Action Buttons */}
                 <div className="flex justify-end gap-4 mb-8">
                   <button
                       onClick={handleRequestQuiz}
                       className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors"
                   >
                        Request Self Assessment
                    </button>

                     <button
                       onClick={handleRequestConsultation}
                          className="bg-darkGreen hover:bg-darkGreen-light text-white px-4 py-2 rounded-md transition-colors"
                     >
                        Request Consultation
                        </button>
                </div>
                   {/* Self Assessments Section */}
                   <div className="mb-8">
                        <div
                            onClick={() => toggleSection('selfAssessments')}
                            className="flex justify-between items-center cursor-pointer bg-gray-50 p-4 rounded-t-lg hover:bg-gray-100"
                        >
                            <h3 className="text-xl font-semibold text-gray-800">Self Assessments</h3>
                            <svg
                                className={`w-6 h-6 transform transition-transform ${openSection.selfAssessments ? 'rotate-180' : ''}`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>

                        {openSection.selfAssessments && (
                          <div className="border border-t-0 rounded-b-lg p-4">
                               {patientDetails.quizData.length > 0 ? (
                                    <div className="bg-white rounded-lg">
                                         {patientDetails.quizData
                                            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                                            .map((quiz, index) => (
                                                <div key={`quiz-${quiz.id || index}`} className="mb-3 p-3 bg-gray-50 rounded shadow-sm flex justify-between items-center">
                                                  <div>
                                                        <p className="text-gray-700">Date: {formatDate(quiz.createdAt)}</p>
                                                        <p className="text-gray-700">Risk Level: {quiz.riskLevel}</p>
                                                   </div>
                                                    <button
                                                        onClick={() => {
                                                            setSelectedQuiz(quiz);
                                                            setShowQuizModal(true);
                                                      }}
                                                        className="p-2 text-darkGreen hover:text-darkGreen-light transition-colors"
                                                        title="View Details"
                                                   >
                                                        <FaEye className="w-5 h-5" />
                                                  </button>
                                              </div>
                                           ))}
                                    </div>
                               ) : (
                                    <p className="text-gray-500">No self assessments taken yet.</p>
                                )}
                         </div>
                     )}
               </div>

                {/* Sleep Studies Section */}
                 <div className="mb-6">
                   <div
                        onClick={() => toggleSection('sleepStudies')}
                         className="flex justify-between items-center cursor-pointer bg-gray-50 p-4 rounded-t-lg hover:bg-gray-100"
                    >
                        <h3 className="text-xl font-semibold text-gray-800">Sleep Studies</h3>
                           <svg
                                 className={`w-6 h-6 transform transition-transform ${openSection.sleepStudies ? 'rotate-180' : ''}`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                             >
                                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                             </svg>
                       </div>

                       {openSection.sleepStudies && (
                             <div className="border border-t-0 rounded-b-lg p-4">
                                 {/* List of Sleep Studies */}
                                 {patientDetails.sleepStudyData && patientDetails.sleepStudyData.length > 0 ? (
                                     <div className="mb-6">
                                         {patientDetails.sleepStudyData
                                             .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                                             .map((study, index) => (
                                                 <div key={study.id || index} className="mb-3 p-4 bg-gray-50 rounded-lg">
                                                     <div className="flex justify-between items-center">
                                                         <div>
                                                             <p className="text-gray-700">{study.sleepStudyType}</p>
                                                             <p className="text-gray-600 text-sm">
                                                                 Date: {format(new Date(study.createdAt), 'MMM dd, yyyy')}
                                                             </p>
                                                         </div>
                                                         <div>
                                                             {getSleepStudyStatusBadge(study.status)}
                                                         </div>
                                                     </div>
                                                 </div>
                                             ))}
                                     </div>
                                 ) : (
                                     <p className="text-gray-500 mb-6">No sleep studies recorded.</p>
                                 )}

                                 {/* Order New Sleep Study */}
                                 <div className="bg-gray-50 rounded-lg p-4 space-y-4">
                                     <div>
                                         <label className="block text-sm font-medium text-gray-700 mb-2">
                                             Sleep Study Type
                                         </label>
                                         <select
                                             value={selectedSleepStudyType}
                                             onChange={(e) => setSelectedSleepStudyType(e.target.value)}
                                             className="border border-gray-300 rounded-md p-2 w-full"
                                             required
                                         >
                                             <option value="">Select Sleep Study Type</option>
                                             {sleepStudyTypes.map(type => (
                                                 <option key={type.id} value={type.id}>
                                                     {type.name}
                                                 </option>
                                             ))}
                                         </select>
                                     </div>
                                     <div className="flex justify-end">
                                         <button
                                             onClick={handleOrderSleepStudy}
                                             disabled={!selectedSleepStudyType || orderingStudy}
                                             className={`bg-darkGreen hover:bg-darkGreen-light text-white font-bold py-2 px-4 rounded transition-colors ${
                                                 orderingStudy ? 'opacity-50 cursor-not-allowed' : ''
                                             }`}
                                         >
                                             {orderingStudy ? 'Ordering...' : 'Order Sleep Study'}
                                         </button>
                                     </div>
                                 </div>
                             </div>
                       )}
                  </div>

                {/* Unassign Button */}
                <div className="border-t pt-6 flex justify-end">
                    <button 
                        onClick={() => setShowUnassignModal(true)} 
                        className="mt-4 bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
                    >
                        Unassign Patient
                    </button>
                </div>

                {/* Unassign Confirmation Modal */}
                {showUnassignModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                            <h3 className="text-lg font-semibold mb-4">Confirm Unassign</h3>
                            <p className="text-gray-600 mb-6">
                                Are you sure you want to unassign this patient?
                            </p>
                            <div className="flex justify-end gap-2">
                                <button
                                    onClick={() => setShowUnassignModal(false)}
                                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => {
                                        handleUnassignPatient();
                                        setShowUnassignModal(false);
                                    }}
                                    className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md transition-colors"
                                >
                                    Unassign
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Quiz Modal - Keep this at the root level */}
                {showQuizModal && selectedQuiz && (
                    <QuizDetailsModal
                        quiz={selectedQuiz}
                        onClose={() => {
                            setShowQuizModal(false);
                            setSelectedQuiz(null);
                        }}
                    />
                )}
            </div>
        </DoctorLayout>
    );
};

export default DoctorPatientRecord;