// features/doctor/components/DoctorProfile.tsx
import React, { useState, useEffect } from 'react';
import DoctorLayout from './DoctorLayout';
import { getDoctorDetails, updateDoctorDetails } from '../services/DoctorService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface DoctorProfileProps {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    specialization: string;
}

const DoctorProfile: React.FC = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [doctor, setDoctor] = useState<DoctorProfileProps>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        specialization: ''
    });

    useEffect(() => {
        fetchDoctorDetails();
    }, []);

    const fetchDoctorDetails = async () => {
        try {
            const doctorId = localStorage.getItem('doctorId');
            if (!doctorId) {
                toast.error('Doctor ID not found');
                return;
            }
            const response = await getDoctorDetails(doctorId);
            setDoctor(response.data.doctor);
        } catch (error) {
            console.error('Error fetching doctor details:', error);
            toast.error('Failed to load doctor details');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDoctor(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const doctorId = localStorage.getItem('doctorId');
            if (!doctorId) {
                toast.error('Doctor ID not found');
                return;
            }
            await updateDoctorDetails(doctorId, doctor);
            toast.success('Profile updated successfully');
            setIsEditing(false);
            // Refresh doctor details
            await fetchDoctorDetails();
        } catch (error) {
            console.error('Error updating doctor details:', error);
            toast.error('Profile update failed: Please try again');
        }
    };

    if (loading) {
        return (
            <DoctorLayout>
                <div className="flex justify-center items-center h-full">
                    <p>Loading...</p>
                </div>
            </DoctorLayout>
        );
    }

    return (
        <DoctorLayout>
            <ToastContainer />
            <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-md">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-800">Doctor Profile</h2>
                    {!isEditing && (
                        <button 
                            onClick={() => setIsEditing(true)} 
                            className="bg-darkGreen text-white px-4 py-2 rounded-md hover:bg-darkGreen-light transition-colors"
                        >
                            Edit Profile
                        </button>
                    )}
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Name Fields Row */}
                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700">First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={doctor.firstName}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={`mt-1 px-3 py-2 border rounded-md block w-full ${isEditing ? 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500' : 'bg-gray-100 text-gray-700'}`}
                                required
                            />
                        </div>
                        <div className="space-y-2">
                            <label className="text-sm font-medium text-gray-700">Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                value={doctor.lastName}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={`mt-1 px-3 py-2 border rounded-md block w-full ${isEditing ? 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500' : 'bg-gray-100 text-gray-700'}`}
                                required
                            />
                        </div>
                    </div>

                    {/* Contact Information */}
                    <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">Email Address</label>
                        <input
                            type="email"
                            name="email"
                            value={doctor.email}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            className={`mt-1 px-3 py-2 border rounded-md block w-full ${isEditing ? 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500' : 'bg-gray-100 text-gray-700'}`}
                            required
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">Mobile Number</label>
                        <input
                            type="tel"
                            name="phoneNumber"
                            value={doctor.phoneNumber}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            className={`mt-1 px-3 py-2 border rounded-md block w-full ${isEditing ? 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500' : 'bg-gray-100 text-gray-700'}`}
                            required
                        />
                    </div>

                    <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">Specialization</label>
                        <input
                            type="text"
                            name="specialization"
                            value={doctor.specialization}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            className={`mt-1 px-3 py-2 border rounded-md block w-full ${isEditing ? 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500' : 'bg-gray-100 text-gray-700'}`}
                            required
                        />
                    </div>

                    {/* Action Buttons */}
                    {isEditing && (
                        <div className="flex justify-end space-x-3 pt-4">
                            <button
                                type="button"
                                onClick={() => setIsEditing(false)}
                                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-darkGreen text-white rounded-md hover:bg-darkGreen-light transition-colors"
                            >
                                Save Changes
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </DoctorLayout>
    );
};

export default DoctorProfile;