import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar: React.FC = () => {
  return (
    <div className="hidden lg:block w-64 bg-white shadow-lg">
      <nav className="mt-6 space-y-2">
        <Link 
          to="/company/dashboard" 
          className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
        >
          Dashboard
        </Link>
        <Link 
          to="/company/reports" 
          className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
        >
          Reports
        </Link>
        <Link 
          to="/company/settings"
          className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
        >
          Settings
        </Link>
        {/* Add a logout link or button if needed */}
      </nav>
    </div>
  );
};

export default Sidebar;