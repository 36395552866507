import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CompanyLogout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear localStorage
    localStorage.removeItem('companyToken');
    localStorage.removeItem('companyId');
    localStorage.removeItem('isCompanyLoggedIn');
    localStorage.removeItem('companyName');
    localStorage.removeItem('companyEmail');
    localStorage.removeItem('companyPhoneNumber');

    // Redirect to login page
    navigate('/auth/company/login');
  }, [navigate]);

  return null; // No need to render anything
};

export default CompanyLogout;