// src/features/auth/services/authService.ts
import { forgotPassword, login, register, resetPassword } from '../../../api/auth/UserAuthApi';
import { LoginFormData, RegisterFormData } from '../types/UserAuthTypes';

export const loginUser = async (formData: LoginFormData) => {
    const response = await login(formData);
    return response;
};

export const registerUser = async (formData: RegisterFormData) => {
    const response = await register(formData);
    
    // Perform any additional logic here if needed after registration
    return response;
};

export const forgotPasswordRequest = async (email: string) => {
    return forgotPassword(email);
};

export const requestPasswordReset = async (token: string, password: string) => {
    return resetPassword(token, password);
};