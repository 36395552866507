import React, { useEffect, useState } from 'react';
import UserSidebar from '../../UserSidebar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchAllSleepStudies, createSleepStudy, removeSleepStudy } from '../services/UserSleepStudyService';
import { SleepStudyData } from '../types/UserSleepStudyTypes';

const UserSleepStudy: React.FC = () => {
    const [sleepStudies, setSleepStudies] = useState<SleepStudyData[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedStudy, setSelectedStudy] = useState<SleepStudyData | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [activeTab, setActiveTab] = useState<'scheduled' | 'completed'>('scheduled');
    const [page, setPage] = useState(1);
    const sleepStudiesPerPage = 5;

    const fetchSleepStudies = async () => {
        const userId = localStorage.getItem('id') as string;

        try {
            setLoading(true);
            const studies = await fetchAllSleepStudies(userId);
            setSleepStudies(studies);
        } catch (err) {
            console.error('Error loading sleep studies', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSleepStudies();
    }, []);

    const handleSeeReportClick = (study: SleepStudyData) => {
        setSelectedStudy(study);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleScheduleClick = () => {
        setShowScheduleModal(true);
    };

    const handleScheduleSubmit = async () => {
        if (!selectedDate) {
            alert('Please select a date.');
            return;
        }

        const formattedDate = selectedDate.toISOString();

        try {
            const userId = localStorage.getItem('id') as string;

            await createSleepStudy(userId, formattedDate);

            alert('We have recieved your request for the Sleep Study. Our team will contact you soon.');
            setShowScheduleModal(false);
            fetchSleepStudies(); // Refresh the list after scheduling
        } catch (error) {
            console.error('Error scheduling sleep study:', error);
        }
    };

    const handleDeleteSchedule = async (studyId: string) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure you want to cancel this scheduled study?")) {
            try {
                await removeSleepStudy(studyId);
                alert("Sleep study schedule cancelled successfully.");
                fetchSleepStudies(); // Refresh the list
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleTabClick = (tab: 'scheduled' | 'completed') => {
        setActiveTab(tab);
        setPage(1); // Reset page to 1 when changing tabs
    };

    // Pagination logic
    const filteredStudies = sleepStudies.filter(study => study.status.toLowerCase() === activeTab);
    const startIndex = (page - 1) * sleepStudiesPerPage;
    const paginatedStudies = filteredStudies.slice(startIndex, startIndex + sleepStudiesPerPage);
    const totalPages = Math.ceil(filteredStudies.length / sleepStudiesPerPage);

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen bg-gray-50 font-sans">
            <UserSidebar />
            <div className="flex-1 overflow-y-auto p-4 lg:p-8">
                <div className="flex flex-col lg:flex-row justify-between items-center mb-4 lg:mb-6">
                    <div className="text-center lg:text-left mb-4 lg:mb-0">
                        <h2 className="text-2xl lg:text-3xl font-bold mb-2">YourNidra Sleep Studies</h2>
                        <p className="text-sm lg:text-base text-gray-600">View scheduled and completed sleep studies.</p>
                    </div>
                    <button
                        onClick={handleScheduleClick}
                        className="bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-4 lg:px-6 rounded-lg shadow-md w-full lg:w-auto"
                    >
                        Schedule a Sleep Study
                    </button>
                </div>

                {/* Tabs for Scheduled and Completed Studies */}
                <div className="mb-4 lg:mb-6 flex border-b border-gray-200 overflow-x-auto">
                    <button
                        className={`px-4 py-2 text-sm font-medium rounded-t-lg flex-shrink-0 ${activeTab === 'scheduled'
                            ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                            : 'bg-gray-200 text-gray-600'
                            }`}
                        onClick={() => handleTabClick('scheduled')}
                    >
                        Scheduled{/* Only show "Sleep Studies" on large screens */}
                        <span className="hidden lg:inline"> Sleep Studies</span>
                    </button>
                    <button
                        className={`ml-2 px-4 py-2 text-sm font-medium rounded-t-lg flex-shrink-0 ${activeTab === 'completed'
                            ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                            : 'bg-gray-200 text-gray-600'
                            }`}
                        onClick={() => handleTabClick('completed')}
                    >
                        Completed{/* Only show "Sleep Studies" on large screens */}
                        <span className="hidden lg:inline"> Sleep Studies</span>
                    </button>
                </div>

                {/* Tab Content: Scheduled Studies */}
                {activeTab === 'scheduled' && (
                    <>
                        {loading ? (
                            <div className="flex justify-center items-center">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-darkGreen" role="status"></div>
                            </div>
                        ) : paginatedStudies.length > 0 ? (
                            <div className="space-y-4 lg:space-y-6">
                                {paginatedStudies.map((study) => (
                                    <div key={study.id} className="relative bg-white rounded-lg shadow-md p-4 lg:p-6 border-l-8 border-blue-500">
                                        <p className="text-xs lg:text-sm text-gray-600 mb-2">
                                            Scheduled for: {new Date(study.date).toLocaleDateString()}
                                        </p>
                                        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end">
                                            <p className="text-gray-700 font-bold">Status: {study.status}</p>
                                            <button
                                                onClick={() => handleDeleteSchedule(study.id)}
                                                className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-4 rounded-lg shadow-md mt-2 lg:mt-0 w-full lg:w-auto"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-center text-gray-600">No sleep study scheduled</p>
                        )}

                        {/* Pagination Controls */}
                        {paginatedStudies.length > 0 && totalPages > 1 && (
                            <div className="flex flex-col lg:flex-row justify-between mt-4 space-y-4 lg:space-y-0">
                                {page > 1 && (
                                    <button
                                        onClick={handlePreviousPage}
                                        className="w-full lg:w-auto bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                    >
                                        Previous
                                    </button>
                                )}
                                {page < totalPages && (
                                    <button
                                        onClick={handleNextPage}
                                        className="w-full lg:w-auto bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                    >
                                        Next
                                    </button>
                                )}
                            </div>
                        )}
                    </>
                )}

                {/* Tab Content: Completed Studies */}
                {activeTab === 'completed' && (
                    <>
                        {loading ? (
                            <div className="flex justify-center items-center">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-darkGreen" role="status"></div>
                            </div>
                        ) : paginatedStudies.length > 0 ? (
                            <div className="space-y-4 lg:space-y-6">
                                {paginatedStudies.map((study) => (
                                    <div key={study.id} className="relative bg-white rounded-lg shadow-md p-4 lg:p-6 border-l-8 border-blue-500">
                                        <p className="text-xs lg:text-sm text-gray-600 mb-2">
                                            Completed on: {new Date(study.createdAt).toLocaleDateString()}
                                        </p>
                                        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end">
                                            <div className="mb-4 lg:mb-0">
                                                <h3 className="text-lg lg:text-xl font-semibold">Sleep Study Report</h3>
                                                <p className="text-xs lg:text-sm text-gray-700 mt-2">AHI: {study.ahi} events/hour (Moderate Apnea)</p>
                                                <p className="text-xs lg:text-sm text-gray-700">Minimum Oxygen Saturation: {study.minOxygen}%</p>
                                            </div>
                                            <button
                                                onClick={() => handleSeeReportClick(study)}
                                                className="bg-darkGreen hover:bg-darkGreen text-white font-bold py-1 px-4 rounded-lg shadow-md mt-2 lg:mt-0 w-full lg:w-auto"
                                            >
                                                See Report
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-center text-gray-600">No sleep study completed</p>
                        )}

                        {/* Pagination Controls */}
                        {paginatedStudies.length > 0 && totalPages > 1 && (
                            <div className="flex flex-col lg:flex-row justify-between mt-4 space-y-4 lg:space-y-0">
                                {page > 1 && (
                                    <button
                                        onClick={handlePreviousPage}
                                        className="w-full lg:w-auto bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                    >
                                        Previous
                                    </button>
                                )}
                                {page < totalPages && (
                                    <button
                                        onClick={handleNextPage}
                                        className="w-full lg:w-auto bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                    >
                                        Next
                                    </button>
                                )}
                            </div>
                        )}
                    </>
                )}

                {/* Schedule a Sleep Study Modal */}
                {showScheduleModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                        <div className="bg-white rounded-lg shadow-lg p-4 lg:p-8 mx-auto w-full max-w-sm lg:max-w-lg h-auto max-h-[90vh] relative">
                            <h2 className="text-lg lg:text-2xl font-semibold text-gray-800 mb-4 text-center">Schedule Your Sleep Study</h2>
                            <p className="text-xs lg:text-sm text-gray-600 mb-6 text-justify lg:text-center px-1">
                                A home sleep study is a convenient and comfortable way to monitor your sleep. You'll use a portable device
                                that tracks your breathing, oxygen levels, and other vital parameters while you sleep at home.
                            </p>

                            <div className="flex flex-col space-y-4 mb-4">
                                <label className="text-gray-700 font-medium w-full lg:w-auto">Select Date:</label>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={(date: Date | null) => {
                                        setSelectedDate(date);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    className="border border-gray-300 p-3 rounded w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    placeholderText="Select date"
                                    minDate={new Date()}
                                    shouldCloseOnSelect={true}
                                    popperClassName="z-50"
                                    onFocus={(e) => e.target.blur()}
                                />
                            </div>

                            <div className="flex flex-col lg:flex-row justify-end mt-6 space-y-4 lg:space-y-0 lg:space-x-3">
                                <button
                                    onClick={() => setShowScheduleModal(false)}
                                    className="w-full lg:w-auto bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 lg:px-6 rounded shadow-md transition-all duration-200"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleScheduleSubmit}
                                    className="w-full lg:w-auto bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-4 lg:px-6 rounded shadow-md transition-all duration-200"
                                >
                                    Schedule
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal for displaying sleep study report */}
                {showModal && selectedStudy && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                        <div className="bg-white rounded-lg shadow-md p-6 lg:p-8 mx-auto w-full max-w-[90vw] h-auto max-h-[90vh] overflow-y-auto relative">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl lg:text-2xl font-bold">Sleep Study Report</h2>
                                <button
                                    className="bg-darkGreen hover:bg-darkGreen-light text-white font-bold py-2 px-4 rounded"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                            </div>
                            <p className="text-xs lg:text-sm text-gray-600 mb-4">Completed on: {new Date(selectedStudy.createdAt).toLocaleDateString()}</p>

                            <p className="text-sm lg:text-base text-gray-700 mb-6">AHI: {selectedStudy.ahi} events/hour</p>
                            <p className="text-sm lg:text-base text-gray-700 mb-6">Minimum Oxygen Saturation: {selectedStudy.minOxygen}%</p>
                            <p className="text-sm lg:text-base text-gray-700 mb-6">Recommendations: {selectedStudy.recommendations}</p>

                            {selectedStudy.reportUrl && (
                                <button
                                    onClick={() => window.open(selectedStudy.reportUrl, '_blank')}
                                    className="w-full lg:w-auto bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-4 rounded"
                                >
                                    Download Full Report
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserSleepStudy;
