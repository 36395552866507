import React, { useCallback, useEffect, useState } from 'react';
import UserSidebar from '../../UserSidebar';
import DatePicker from 'react-datepicker';
import { fetchConsultations, createConsultation, cancelConsultation } from '../services/UserConsultationService';
import { ConsultationData } from '../types/UserConsultationTypes';
import { fromZonedTime } from 'date-fns-tz';

const UserConsultation: React.FC = () => {
    const [consultations, setConsultations] = useState<ConsultationData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [selectedConsultation] = useState<ConsultationData | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedTime, setSelectedTime] = useState<string>('10:00 AM');
    const [timeError, setTimeError] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<'scheduled' | 'completed' | 'cancelled'>('scheduled');
    const consultationsPerPage = 5; // Number of consultations per page
    const [page, setPage] = useState(1);

    // Fetch consultations with error handling
    const fetchAllConsultations = useCallback(async () => {
        const userId = localStorage.getItem('id');
        const token = localStorage.getItem('token');

        if (userId && token) {
            try {
                setLoading(true);
                const consultations = await fetchConsultations(userId, token);
                setConsultations(consultations);
            } catch (error: any) {
                if (error.response?.status === 404) {
                    setError(`No ${activeTab} consultations found.`);
                } else {
                    setError('Failed to load consultations.');
                }
            } finally {
                setLoading(false);
            }
        }
    }, [activeTab]);

    useEffect(() => {
        fetchAllConsultations();
    }, [fetchAllConsultations]);

    // Handle tab switching and pagination
    const filteredConsultations = consultations.filter(c => c.status.toLowerCase() === activeTab);
    const startIndex = (page - 1) * consultationsPerPage;
    const paginatedConsultations = filteredConsultations.slice(startIndex, startIndex + consultationsPerPage);
    const totalPages = Math.ceil(filteredConsultations.length / consultationsPerPage);

    const handleTabClick = (tab: 'scheduled' | 'completed' | 'cancelled') => {
        setActiveTab(tab);
        setPage(1);
    };

    const handleScheduleSubmit = async () => {
        if (!selectedDate || !selectedTime) {
            setTimeError('Please select a valid date and time.');
            return;
        }

        try {
            // Convert the selected time into 24-hour format
            const [time, period] = selectedTime.split(' ');
            let [hours, minutes] = time.split(':').map(Number);

            if (period === 'PM' && hours < 12) hours += 12; // Convert PM to 24-hour format
            if (period === 'AM' && hours === 12) hours = 0; // Handle 12 AM case

            // Combine the selected date and parsed time into a single Date object
            const dateWithTime = new Date(selectedDate);
            dateWithTime.setHours(hours, minutes, 0, 0);

            // Specify the time zone of the input date and time (IST in this example)
            const timeZone = 'Asia/Kolkata';

            // Convert the local date and time to UTC
            const utcDate = fromZonedTime(dateWithTime, timeZone);

            // Format the UTC date to ISO string
            const formattedDate = utcDate.toISOString();

            // Send the formatted UTC datetime to the backend
            const userId = localStorage.getItem('id');
            const token = localStorage.getItem('token');
            if (userId && token) {
                await createConsultation(userId, formattedDate, selectedTime, token);
                setShowScheduleModal(false);

                // Clear form state
                setSelectedDate(null);
                setSelectedTime('10:00 AM');
                setTimeError(null);

                setActiveTab('completed');
                alert('We have recieved your request for the doctor consultation. Our team will contact you soon.');

                setActiveTab('scheduled');
                fetchAllConsultations(); // Refresh consultations after scheduling
            }
        } catch (error) {
            console.error('Error scheduling consultation:', error);
        }
    };

    const handleCancelConsultation = async (consultationId: string) => {
        const token = localStorage.getItem('token');

        // Show confirmation dialog before proceeding
        const isConfirmed = window.confirm("Are you sure you want to cancel this consultation?");

        if (isConfirmed && token) { // Proceed only if user confirms
            try {
                await cancelConsultation(consultationId, token);
                setConsultations(consultations.filter((c) => c._id !== consultationId));
            } catch (error) {
                console.error('Failed to cancel consultation:', error);
            }
        }
    };

    const timeOptions = [
        '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM',
        '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM',
        '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM'
    ];

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen bg-gray-50 font-sans">
            <UserSidebar />
            <div className="flex-1 overflow-y-auto p-8 lg:p-8">
                <div className="flex flex-col lg:flex-row justify-between items-center mb-4 lg:mb-6">
                    <div className="text-center lg:text-left mb-4 lg:mb-0">
                        <h2 className="text-2xl lg:text-3xl font-bold mb-2">Doctor Consultations</h2>
                        <p className="text-sm lg:text-base text-gray-600">View details of doctor consultations and reports.</p>
                    </div>
                    <button
                        onClick={() => setShowScheduleModal(true)}
                        className="bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-4 lg:px-6 rounded-lg shadow-md w-full lg:w-auto"
                    >
                        Schedule a Consultation
                    </button>
                </div>

                {/* Tabs */}
                <div className="mb-4 lg:mb-6 flex border-b border-gray-200">
                    <button
                        onClick={() => handleTabClick('scheduled')}
                        className={`px-4 py-2 text-sm font-medium rounded-t-lg ${activeTab === 'scheduled'
                            ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                            : 'bg-gray-200 text-gray-600'
                            }`}
                        style={{ flexShrink: 0 }} // Prevent shrinking
                    >
                        Scheduled
                        <span className="hidden lg:inline"> Consultations</span>
                    </button>
                    <button
                        onClick={() => handleTabClick('completed')}
                        className={`ml-2 px-4 py-2 text-sm font-medium rounded-t-lg ${activeTab === 'completed'
                            ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                            : 'bg-gray-200 text-gray-600'
                            }`}
                        style={{ flexShrink: 0 }} // Prevent shrinking
                    >
                        Completed
                        <span className="hidden lg:inline"> Consultations</span>
                    </button>
                    <button
                        onClick={() => handleTabClick('cancelled')}
                        className={`ml-2 px-4 py-2 text-sm font-medium rounded-t-lg ${activeTab === 'cancelled'
                            ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                            : 'bg-gray-200 text-gray-600'
                            }`}
                        style={{ flexShrink: 0 }} // Prevent shrinking
                    >
                        Cancelled
                        <span className="hidden lg:inline"> Consultations</span>
                    </button>
                </div>

                {/* Display Consultations */}
                {loading ? (
                    <div className="flex justify-center">Loading...</div>
                ) : error ? (
                    <p className="text-center text-gray-600">{error}</p>
                ) : (
                    <div>
                        {paginatedConsultations.length > 0 ? (
                            paginatedConsultations.map(consultation => (
                                <div key={consultation._id} className="relative bg-white rounded-lg shadow-md p-4 border-l-8 border-blue-500 mb-4">
                                    <p className="text-sm text-gray-600 mb-2">
                                        Appointment Time: {new Date(consultation.date).toLocaleDateString()} at {consultation.time}
                                    </p>
                                    <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end">
                                        <div>
                                            <p className="text-gray-700 font-bold">Status: {consultation.status}</p>
                                        </div>
                                        {/* On mobile, make the cancel button full width. On larger screens, keep it as is. */}
                                        <button
                                            onClick={() => handleCancelConsultation(consultation._id)}
                                            className="mt-4 lg:mt-0 w-full lg:w-auto bg-red-500 text-white font-bold py-2 px-4 rounded-lg shadow-md hover:bg-red-600 transition-all"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center text-gray-600">
                                {activeTab === 'scheduled' && 'No consultations scheduled'}
                                {activeTab === 'completed' && 'No consultations completed'}
                                {activeTab === 'cancelled' && 'No consultations cancelled'}
                            </p>
                        )}

                        {/* Pagination Controls */}
                        {filteredConsultations.length > consultationsPerPage && (
                            <div className="flex flex-col lg:flex-row justify-between mt-4 space-y-4 lg:space-y-0">
                                {page > 1 && (
                                    <button
                                        onClick={handlePreviousPage}
                                        className="w-full lg:w-auto bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                    >
                                        Previous
                                    </button>
                                )}
                                {page < totalPages && (
                                    <button
                                        onClick={handleNextPage}
                                        className="w-full lg:w-auto bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md"
                                    >
                                        Next
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {/* Schedule a Consultation Modal */}
                {showScheduleModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                        <div className="bg-white rounded-lg shadow-lg p-6 lg:p-8 w-full max-w-lg h-auto max-h-[90vh] relative">
                            <h2 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-4 text-center">Schedule Your Consultation</h2>
                            <p className="text-sm lg:text-base text-gray-600 mb-6 text-center">
                                Choose a suitable date and time to schedule your consultation with our specialist. Our available slots are from 10:00 AM to 6:00 PM.
                            </p>
                            <div className="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-3 mb-4">
                                <label className="text-gray-700 font-medium w-full lg:w-auto text-left lg:text-right">Select Date:</label>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={(date: Date | null) => setSelectedDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    className="border border-gray-300 p-3 rounded w-full lg:max-w-xs focus:outline-none"
                                    placeholderText="Select date"
                                    minDate={new Date()}
                                    onFocus={(e) => e.target.blur()}
                                />
                            </div>

                            {/* Time Selection */}
                            <div className="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-3 mb-4">
                                <label className="text-gray-700 font-medium w-full lg:w-auto text-left lg:text-right">Select Time:</label>
                                <select
                                    value={selectedTime}
                                    onChange={(e) => setSelectedTime(e.target.value)}
                                    className="border border-gray-300 p-3 rounded w-full lg:max-w-xs focus:outline-none"
                                >
                                    {timeOptions.map((time) => (
                                        <option key={time} value={time}>
                                            {time}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {timeError && <p className="text-red-500 text-sm mb-4 text-center">{timeError}</p>}

                            <div className="flex flex-col lg:flex-row justify-end mt-6 space-y-4 lg:space-y-0 lg:space-x-3">
                                <button
                                    onClick={() => setShowScheduleModal(false)}
                                    className="w-full lg:w-auto bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 lg:px-6 rounded shadow-md transition-all duration-200"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleScheduleSubmit}
                                    className="w-full lg:w-auto bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-4 lg:px-6 rounded shadow-md transition-all duration-200"
                                >
                                    Schedule
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal for displaying consultation report */}
                {showModal && selectedConsultation && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                        <div className="bg-white rounded-lg shadow-md p-6 lg:p-8 mx-auto w-full max-w-[90vw] h-auto max-h-[90vh] overflow-y-auto relative">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl lg:text-2xl font-bold">Consultation Report</h2>
                                <button
                                    className="bg-darkGreen hover:bg-darkGreen-light text-white font-bold py-2 px-4 rounded"
                                    onClick={() => setShowModal(false)}
                                >
                                    Close
                                </button>
                            </div>
                            <p className="text-sm lg:text-base text-gray-600 mb-4">
                                Completed on: {new Date(selectedConsultation.createdAt).toLocaleDateString()}
                            </p>

                            <p className="text-sm lg:text-base text-gray-700 mb-6">{selectedConsultation.recommendations}</p>

                            {selectedConsultation.reportUrl && (
                                <button
                                    onClick={() => window.open(selectedConsultation.reportUrl, '_blank')}
                                    className="w-full lg:w-auto bg-darkGreen hover:bg-darkGreen-light text-white font-bold py-2 px-4 rounded"
                                >
                                    Download Full Report
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserConsultation;