// src/api/authApi.ts
import { LoginFormData, RegisterFormData } from '../../features/user/types/UserAuthTypes';
import axiosInstance from '../AxiosInstance';

export const login = (formData: LoginFormData) => {
    return axiosInstance.post('/user/login', formData);
};

export const register = (formData: RegisterFormData) => {
    return axiosInstance.post('/user/register', formData);
};

export const forgotPassword = (email: string) => {
    return axiosInstance.post('/user/forgot-password', { email });
};

export const resetPassword = (token: string, password: string) => {
    return axiosInstance.post(`/user/reset-password/${token}`, { password });
};
