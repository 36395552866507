import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './shared/Navbar';
import Hero from './home/Hero';
import Testimonials from './home/Testimonials';
import Footer from './shared/Footer';
import Symptoms from './home/Symptoms';
import Diagnosis from './home/Diagnosis';
import About from './home/About';
import Quiz from './pages/Quiz';
import Blogs from './features/blogs/components/Blogs';
import BlogDetail from './features/blogs/components/BlogDetail';
import BlogsCarousel from './home/BlogsCarousel';
import BlankPage from './pages/BlankPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import CreateMultipleUsers from './features/admin/components/CreateMultipleUsers';
import UserDashboard from './features/user/dashboard/components/UserDashboard';
import UserLogout from './features/company/Logout';
import UserConsultation from './features/user/consultation/components/UserConsultation';
import UserFAQ from './features/user/faq/components/UserFAQ';
import UserProfile from './features/user/profile/components/UserProfile';
import UserQuestionnaire from './features/user/questionnaire/components/UserQuestionnaire';
import UserReferral from './features/user/referral/components/UserReferral';
import UserSleepStudy from './features/user/sleepStudy/components/UserSleepStudy';
import UserDevice from './features/user/device/components/UserDevice';

import FullQuiz from './pages/fullQuiz';
import UserLogin from './features/auth/user/UserLogin';
import UserRegister from './features/auth/user/UserRegister';
import UserForgotPassword from './features/auth/user/UserForgotPassword';
import UserResetPassword from './features/auth/user/UserResetPassword';
import CompanyLogin from './features/auth/company/CompanyLogin';
import CompanyRegister from './features/auth/company/CompanyRegister';
import CompanyForgotPassword from './features/auth/company/CompanyForgotPassword';
import CompanyResetPassword from './features/auth/company/CompanyResetPassword';
import CompanyDashboard from './features/company/components/CompanyDashboard';
import CompanyReports from './features/company/components/CompanyReports';
import CompanySettings from './features/company/components/CompanySettings';
import CompanyLogout from './features/company/components/CompanyLogout';
import DoctorLogin from './features/auth/doctor/DoctorLogin';
import DoctorRegister from './features/auth/doctor/DoctorRegister';
import DoctorResetPassword from './features/auth/doctor/DoctorResetPassword';
import DoctorForgotPassword from './features/auth/doctor/DoctorForgotPassword';
import DoctorDashboard from './features/doctor/components/DoctorDashboard';
import DoctorVerifyOTP from './features/auth/doctor/DoctorVerifyOTP';
import DoctorSchedule from './features/doctor/components/DoctorSchedule';
import DoctorLogout from './features/auth/doctor/DoctorLogout';
import DoctorPatientList from './features/doctor/components/DoctorPatientList';
import DoctorPatientRecord from './features/doctor/components/DoctorPatientRecord';
import DoctorProfile from './features/doctor/components/DoctorProfile';
import DoctorHospitals from './features/doctor/components/DoctorHospitals';


const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <div className="max-w-7xl mx-auto lg:px-8 py-16">
        {children}
    </div>
);

const Home: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <Hero />
            <Symptoms />
            <About />
            <Diagnosis />
            <BlogsCarousel />
            <Testimonials />
        </Layout>
    );
};

const BlogPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <Blogs />
        </Layout>
    );
};

const BlogDetailPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout>
            <BlogDetail />
        </Layout>
    );
};

// A higher-order component to protect routes
const ProtectedRoute: React.FC<{ element: React.ReactElement, requiredRole?: 'user' | 'doctor' }> = ({ element, requiredRole = 'user' }) => {
    const isUserAuthenticated = localStorage.getItem('isUserLoggedIn') === 'true';
    const isDoctorAuthenticated = localStorage.getItem('isDoctorLoggedIn') === 'true';

    if (requiredRole === 'doctor') {
        return isDoctorAuthenticated ? element : <Navigate to="/auth/doctor/login" />;
    }

    return isUserAuthenticated ? element : <Navigate to="/auth/user/login" />;
};

const App: React.FC = () => {
    return (
        <div className="font-sans">
            <Navbar />
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/quiz" element={<Quiz />} />
                    <Route path="/shell/quiz" element={<FullQuiz />} />
                    <Route path="/blogs" element={<BlogPage />} />
                    <Route path="/blog/:id" element={<BlogDetailPage />} />
                    <Route path="/blank-page" element={<BlankPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />

                    {/* Admin routes*/}
                    <Route path="/admin/create-users" element={<CreateMultipleUsers />} />

                    {/* Routes for user authentication */}
                    <Route path="/auth/user/login" element={<UserLogin />} />
                    <Route path="/auth/user/register" element={<UserRegister />} />
                    <Route path="/auth/user/forgot-password" element={<UserForgotPassword />} />
                    <Route path="/auth/user/reset-password/:token" element={<UserResetPassword />} />
                    <Route path="/auth/user/logout" element={<UserLogout />} />

                    {/* User Protected routes */}
                    <Route path="/user/dashboard" element={<ProtectedRoute element={<UserDashboard />} />} />
                    <Route path="/user/profile" element={<ProtectedRoute element={<UserProfile />} />} />
                    <Route path="/user/questionnaire" element={<ProtectedRoute element={<UserQuestionnaire />} />} />
                    <Route path="/user/sleep-study" element={<ProtectedRoute element={<UserSleepStudy />} />} />
                    <Route path="/user/consult" element={<ProtectedRoute element={<UserConsultation />} />} />
                    <Route path="/user/device" element={<ProtectedRoute element={<UserDevice />} />} />
                    <Route path="/user/referral" element={<ProtectedRoute element={<UserReferral />} />} />
                    <Route path="/user/faq" element={<ProtectedRoute element={<UserFAQ />} />} />

                    {/* Routes for company authentication */}
                    <Route path="/auth/company/login" element={<CompanyLogin />} />
                    <Route path="/auth/company/register" element={<CompanyRegister />} />
                    <Route path="/auth/company/forgot-password" element={<CompanyForgotPassword />} />
                    <Route path="/auth/company/reset-password/:token" element={<CompanyResetPassword />} />

                    {/* Company Protected routes */}
                    <Route path="/company/dashboard" element={<ProtectedRoute element={<CompanyDashboard />} />} />
                    <Route path="/company/reports" element={<ProtectedRoute element={<CompanyReports />} />} />
                    <Route path="/company/settings" element={<ProtectedRoute element={<CompanySettings />} />} />
                    <Route path="/company/logout" element={<CompanyLogout />} />

                    {/* Routes for doctor authentication */}
                    <Route path="/auth/doctor/login" element={<DoctorLogin />} />
                    <Route path="/auth/doctor/register" element={<DoctorRegister />} />
                    <Route path="/auth/doctor/verify-otp" element={<DoctorVerifyOTP />} />
                    <Route path="/auth/doctor/forgot-password" element={<DoctorForgotPassword />} />
                    <Route path="/auth/doctor/reset-password/:token" element={<DoctorResetPassword />} />
                    <Route path="/auth/doctor/logout" element={<DoctorLogout />} />

                    {/* Doctor Protected routes */}
                    <Route path="/doctor/dashboard" element={<ProtectedRoute element={<DoctorDashboard />} requiredRole="doctor" />} />
                    <Route
                        path="/doctor/manage-patients" element={<ProtectedRoute element={<DoctorPatientList />} requiredRole="doctor"/>}
                    />
                    <Route path="/doctor/schedule" element={<ProtectedRoute element={<DoctorSchedule />} requiredRole="doctor" />} />
                    <Route path="/doctor/patient/:patientId" element={<ProtectedRoute element={<DoctorPatientRecord />} requiredRole="doctor" />} />
                    <Route path="/doctor/profile" element={<ProtectedRoute element={<DoctorProfile />} requiredRole="doctor" />} />
                    <Route path="/doctor/manage-hospitals" element={<ProtectedRoute element={<DoctorHospitals />} requiredRole="doctor" />} />


                </Routes>
                <Footer />
            </Layout>
        </div>
    );
};

const AppWrapper: React.FC = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;
