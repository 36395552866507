import React, { useEffect, useState } from 'react';
import UserSidebar from '../../UserSidebar';
import ProgressCard from '../../UserProgressCard';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchProgressData, saveUserData } from '../services/UserDashboardService';
import { ProgressData } from '../types/UserDashboardTypes';
import { format } from 'date-fns';

const UserDashboard: React.FC = () => {
    const [progressData, setProgressData] = useState<ProgressData[]>([]);
    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [modalInput, setModalInput] = useState<string>('');
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<string>('');
    const userName = localStorage.getItem('userName') || 'John Doe';
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    useEffect(() => {
        const missing: string[] = [];
        const fieldsToCheck = ['userFirstName', 'userLastName', 'userEmail', 'userPhoneNumber', 'userGender', 'userDateOfBirth', 'userAddress'];

        fieldsToCheck.forEach((field) => {
            const value = localStorage.getItem(field);
            if (!value || value === 'undefined') {
                missing.push(field);
            }
        });

        if (missing.length > 0) {
            setMissingFields(missing);
            setShowModal(true);
        } else {
            fetchUserProgress();
        }
    }, []);

    const fetchUserProgress = async () => {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('userToken');

        if (userId && token) {
            try {
                const progress = await fetchProgressData(userId, token);
                setProgressData(progress);
            } catch (error) {
                console.error('Error fetching progress data:', error);
            }
        }
    };

    const handleModalSubmit = async () => {
        const field = missingFields[currentQuestion];

        if (field !== 'dateOfBirth' && modalInput.trim() === '') {
            setError('This field cannot be empty');
            return;
        }

        if (field === 'dateOfBirth' && selectedDate === null) {
            setError('Please select your date of birth');
            return;
        }

        // Save field values in localStorage
        if (field === 'dateOfBirth' && selectedDate) {
            const formattedDate = format(selectedDate, 'yyyy-MM-dd');
            localStorage.setItem(field, formattedDate);
        } else {
            localStorage.setItem(field, modalInput.trim());
        }

        setModalInput('');
        setSelectedDate(null);
        setError('');

        // Proceed to the next question or submit the final data
        if (currentQuestion + 1 < missingFields.length) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            setShowModal(false);

            const updatedData = {
                firstName: localStorage.getItem('userFirstName') || undefined,
                lastName: localStorage.getItem('userLastName') || undefined,
                email: localStorage.getItem('userEmail') || undefined,
                mobile: localStorage.getItem('userPhoneNumber') || undefined,
                dateOfBirth: localStorage.getItem('userDateOfBirth') || undefined,
                gender: localStorage.getItem('userGender') || undefined,
                address: localStorage.getItem('userAddress') || undefined,
            };

            try {
                const userId = localStorage.getItem('userId');
                const token = localStorage.getItem('userToken');
                if (userId && token) {
                    await saveUserData(userId, updatedData, token);
                    window.location.reload();
                }
            } catch (error) {
                console.error('Error updating user data:', error);
                setError('Failed to update user data.');
            }
        }
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen bg-gray-50 font-sans">
            <UserSidebar />
            <div className="flex-1 overflow-y-auto p-8 lg:p-8">
                <header className="mb-4 lg:mb-8 flex flex-col lg:flex-row items-center justify-between">
                    <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-center lg:text-left">
                        Welcome, {userName}
                    </h1>
                </header>

                <div className="space-y-4 lg:space-y-8 relative">
                    {progressData.map((step, index) => (
                        <ProgressCard
                            key={index}
                            title={step.title}
                            status={step.status}
                            date={step.date}
                            linkTo={step.linkTo}
                            className="w-full lg:w-auto" // Full width on mobile, auto on desktop
                        />
                    ))}
                </div>

                {showModal && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
                        <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-md max-w-lg mx-4">
                            <h2 className="text-xl sm:text-2xl font-bold mb-4 text-darkGreen text-center">
                                Complete Your Profile
                            </h2>
                            <p className="text-sm text-gray-600 mb-6 text-center">
                                We need more details to enhance your experience. Please fill in the missing information.
                            </p>

                            {/* Display the label for the current question */}
                            <h3 className="text-md sm:text-lg font-semibold mb-4 text-center text-gray-800">
                                {getQuestionLabel(missingFields[currentQuestion])}
                            </h3>

                            {/* Centering container for input */}
                            <div className="flex justify-center">
                                {/* Input field: Date picker or text area */}
                                {missingFields[currentQuestion] === 'userDateOfBirth' ? (
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={(date: Date | null) => setSelectedDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        className="border border-gray-300 p-2 rounded w-4/5 text-gray-700 focus:outline-none focus:ring-2 focus:ring-darkGreen text-center"
                                        placeholderText="Select your date of birth"
                                        showYearDropdown
                                        showMonthDropdown
                                        dropdownMode="select" // For better dropdown UX
                                        scrollableYearDropdown // If you want to show more year options
                                        yearDropdownItemNumber={100} // Number of years to show in dropdown (optional)
                                        maxDate={new Date()}
                                    />
                                ) : (
                                    <textarea
                                        value={modalInput}
                                        onChange={(e) => setModalInput(e.target.value)}
                                        className="border border-gray-300 p-2 rounded w-4/5 text-gray-700 focus:outline-none focus:ring-2 focus:ring-darkGreen text-center"
                                        placeholder={getQuestionLabel(missingFields[currentQuestion])}
                                        rows={3}
                                    />
                                )}
                            </div>

                            {/* Validation error message */}
                            {error && <p className="text-red-500 text-sm mt-2 text-center">{error}</p>}

                            {/* Modal footer: Submit button */}
                            <div className="flex justify-center mt-6">
                                <button
                                    onClick={handleModalSubmit}
                                    className="bg-darkGreen text-white py-2 px-6 rounded-md hover:bg-green-700 transition-all duration-200 ease-in-out"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const getQuestionLabel = (field: string) => {
    switch (field) {
        case 'userFirstName':
            return 'What is your first name?';
        case 'userLastName':
            return 'What is your last name?';
        case 'userEmail':
            return 'What is your email address?';
        case 'userPhoneNumber':
            return 'What is your mobile number?';
        case 'userDateOfBirth':
            return 'What is your date of birth?';
        case 'userGender':
            return 'What is your gender?';
        case 'userAddress':
            return 'What is your address?';
        default:
            return '';
    }
};

export default UserDashboard;