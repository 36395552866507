// features/auth/doctor/DoctorLogout.tsx (Logout functionality)
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DoctorLogout: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear doctor-related items from localStorage
        localStorage.removeItem('doctorToken');
        localStorage.removeItem('doctorId');
        localStorage.removeItem('isDoctorLoggedIn');
        localStorage.removeItem('doctorFirstName');
        localStorage.removeItem('doctorLastName');

        navigate('/auth/doctor/login');
    }, [navigate]);

    // No need to return any JSX since this component just redirects
    return null;
};

export default DoctorLogout;