// src/api/authApi.ts
import axiosInstance from '../AxiosInstance';
import { LoginFormData, RegisterFormData } from '../../features/company/types/CompanyAuthTypes';

export const login = (formData: LoginFormData) => {
    return axiosInstance.post('/company/login', formData);
};

export const register = (formData: RegisterFormData) => {
    return axiosInstance.post('/company/register', formData);
};

export const forgotPassword = (email: string) => {
    return axiosInstance.post('/company/forgot-password', { email });
};

export const resetPassword = (token: string, password: string) => {
    return axiosInstance.post(`/company/reset-password/${token}`, { password });
};
