// src/api/sleepStudyApi.ts
import axiosInstance from '../AxiosInstance';
import { SleepStudyData } from '../../features/user/sleepStudy/types/UserSleepStudyTypes';

export const getSleepStudies = async (userId: string): Promise<SleepStudyData[]> => {
    try {
        const response = await axiosInstance.get(`/sleep-study/user/all/${userId}`);
        return response.data.sleepStudyData;
    } catch (error: any) {
        if (error.response?.status === 404) {
            console.warn('No sleep studies found for user.');
            return []; // Return an empty array if no sleep studies are found
        }
        throw new Error('Failed to fetch sleep studies');
    }
};

export const scheduleSleepStudy = async (userId: string, date: string): Promise<void> => {
    try {
        await axiosInstance.post('/sleep-study/schedule', {
            userId,
            date,
            status: 'Scheduled',
        });
    } catch (error) {
        throw new Error('Failed to schedule sleep study');
    }
};

export const deleteSleepStudy = async (studyId: string): Promise<void> => {
    try {
        await axiosInstance.delete(`/sleep-study/${studyId}`);
    } catch (error) {
        throw new Error('Failed to delete sleep study schedule');
    }
};